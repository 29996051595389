import React, { useEffect, useRef } from "react";
import {
    Grid,
    InputAdornment,
    MenuItem,
    Popover,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import Style from "./style";
import { SearchIcon } from "../../assets/svg";
import ShowResults from "./showResults";

const SearchSuggestionComponent = (props) => {
    const { className } = props;

    /**
     * define variables
     */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchWidth, setSearchWidth] = React.useState(0);

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    // getting field width
    const fieldRef = useRef(null);

    useEffect(() => {
        if (fieldRef.current) {
            setSearchWidth(fieldRef.current.getBoundingClientRect().width);
        }
    }, [])

    console.log("searchWidth", searchWidth)


    return (
        <Grid
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
            className={className}
        >
            <Grid className="searchHeader w-100">
                <TextField
                    ref={fieldRef}
                    className="w-100"
                    placeholder="Search..."
                    id="outlined-basic"
                    variant="outlined"
                    size="medium"
                    // onChange={(event) => setAnchorEl(event.currentTarget)}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }} />
            </Grid>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                className={className}
                sx={{ '& .MuiPaper-root': { width: searchWidth } }}
            >

                {/* Suggested list */}
                <Grid sx={{ p: 2 }}>
                    <Typography variant="subtitle1" color={"textSecondary"} className="uppercase" sx={{ pr: 1, pl: 1, pb: 1 }}>
                        Suggested filters
                    </Typography>
                    {/* if selected or Active add class "active" */}
                    <MenuItem className="suggestListItem" sx={{ pr: 1, pl: 1, mb: 1 }}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid className="flexAlign">
                                <Typography variant="body1" className="prefix" color={"textSecondary"}>Is</Typography>
                                <Typography variant="body1" className="typeText" color={"textSecondary"}>Object Type</Typography>
                            </Grid>
                            <Typography variant="body1" className="exampleText" color={"textSecondary"}>Ex: is:payment</Typography>
                        </Grid>
                    </MenuItem>
                    <MenuItem className="suggestListItem active" sx={{ pr: 1, pl: 1, mb: 1 }}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid className="flexAlign">
                                <Typography variant="body1" className="prefix" color={"textSecondary"}>Is</Typography>
                                <Typography variant="body1" className="typeText" color={"textSecondary"}>Object Type</Typography>
                            </Grid>
                            <Typography variant="body1" className="exampleText" color={"textSecondary"}>Ex: is:payment</Typography>
                        </Grid>
                    </MenuItem>
                </Grid>
                {/* Suggested list */}

                {/* Showing results */}
                <Grid>
                    <ShowResults
                        searchSuggestion
                        handleAllResults={() => setAnchorEl(null)} />
                </Grid>
                {/* Showing results */}


            </Popover>
        </Grid>
    );
};

const SearchSuggestion = styled(SearchSuggestionComponent)(({ theme }) => Style(theme));

export default SearchSuggestion;
