import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Avatar,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Style from "./style";
import { AdorementEmail, AdorementLock, AdorementUser, BuildingIcon, UserIcon } from "../../../assets/svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../store/actions/agenciesAction";
import { useNavigate, useParams } from "react-router-dom";

const UserInfo = (props) => {
  const { className } = props;

  const { id } = useParams();

  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.userInfo);
  const { userInfo, ownedAgencies, workspace } = list;

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getUserInfo(id))
  }, [dispatch, id])


  const role = userInfo?.user_role ? JSON.parse(userInfo?.user_role) : {};
  const type = role[userInfo?.agency_pref]?.type;
  const joined_date = moment(userInfo.created_on ? userInfo.created_on : userInfo.modified_on).format('DD-MM-YYYY');

  const [agencyCount, setAgencyCount] = useState(6);
  const [workspaceCount, setWorkspaceCount] = useState(6);

  //topCard

  return (
    <Grid
      container
      alignItems={"center"}
      className={`${className} h-100`}
    >
      {/* Header */}
      <Grid container alignItems={"center"} className="breadcrumb">
        <Typography variant="h5" className="fw-700 cursorPointer" color={"text.greyText2"} onClick={() => {
          navigate("/agencies/users")
        }}>
          Users {" > "}
        </Typography>
        <Typography variant="h5" className="fw-700 pl-1">User Info</Typography>
      </Grid>
      {/* Header */}

      <Grid item xs={12} className="userGroupContainer">

        {/* user group name */}
        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>User Name</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>User name and the joined date in to the mentioned agency.</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container justifyContent={"space-between"}>
              <Grid item className="dflex aCenter">
                <Avatar sx={{ width: 64, height: 64 }} />
                <Grid sx={{ ml: 2 }}>
                  <Typography variant="h4" className="mb-1">{userInfo.username} </Typography>
                  <Typography variant="body2" className="dflex aCenter"><UserIcon /><span className="ml-1">{type} @ {userInfo.agency_name} </span> </Typography>
                </Grid>
              </Grid>
              <Grid>
                <Grid className="dflex aCenter jEnd mb-1">
                  <Typography variant="body2" className="fs-10" color={"text.greyText5"}>Joined Date :</Typography>
                  <Typography variant="body2" className="fs-10 fw-500" color={"text.greyText4"}>{joined_date}</Typography>
                </Grid>
                {/* <Grid className="dflex aCenter jEnd">
                  <Typography variant="body2" className="fs-10" color={"text.greyText5"}>By :</Typography>
                  <Typography variant="body2" className="fs-10 fw-500" color={"text.greyText4"}>Micheal Emmanual</Typography>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* user group name */}

        <Divider sx={{ mt: 5, mb: 5, width: '100%' }} />

        {/* user group name */}
        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>User Control</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>The above user was added by the following person into the sales dashboard.</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" color="text.labelText" className="pb-1">Email</Typography>
                <TextField
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><AdorementEmail /></InputAdornment>,
                  }}
                  value={userInfo.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" color="text.labelText" className="pb-1">User Name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><AdorementUser /></InputAdornment>,
                  }}
                  value={userInfo.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" color="text.labelText" className="pb-1">First Name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><AdorementUser /></InputAdornment>,
                  }}
                  value={userInfo.first_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" color="text.labelText" className="pb-1">Last Name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><AdorementUser /></InputAdornment>,
                  }}
                  value={userInfo.last_name}
                />
              </Grid>
              <Grid item xs={12} sm={6} display="none">
                <Typography variant="body1" color="text.labelText" className="pb-1">Password</Typography>
                <TextField
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><AdorementLock /></InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* user group name */}
        <Divider sx={{ mt: 5, mb: 5, width: '100%' }} />

        {/* Owned Agencies */}
        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>Owned Agencies</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>These are the agencies owned by the above user</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>

              {/* Loop */}
              {Object.entries(ownedAgencies).slice(0, agencyCount).map(([key, value]) => {
                return (
                  <Grid item xs={12} md={4}>
                    <Grid container justifyContent={"space-between"} alignItems={"center"} className="agenciesItem">
                      <Grid className="dflex aCenter">
                        {value.agency_logo ? <img src={value.agency_logo} height="35px" width="35px" alt="" /> :
                          <Avatar />}
                        <Grid className="pl-1">
                          <Typography variant="body2" className="fw-600">{value.agency_name}</Typography>
                          <Typography variant="body2" color={"text.greyText4"} className="dflex mt5 fs-10"><BuildingIcon /><span className="ml-1">{value.category}</span></Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>)
              })}
              {/* Loop */}

              {ownedAgencies?.length - 1 >= 6 ?
                <Typography className="jEnd dflex primary1 w-100 cursorPointer" onClick={() => { agencyCount === 6 ? setAgencyCount(ownedAgencies?.length - 1) : setAgencyCount(6) }} > {agencyCount === 6 ? `Show more (${ownedAgencies?.length - 6})` : "Show Less"}</Typography>
                : ""}
            </Grid>
          </Grid>
        </Grid>
        {/* Owned Agencies */}

        <Divider sx={{ mt: 5, mb: 5, width: '100%' }} />

        {/* Activities */}
        {/* <Grid container >
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>Activities</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>These are the agencies owned by the above user</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>

              <Grid item className="activityItem">
                <Typography variant="body2" className="fs-10 fw-500" align="center">TASKS</Typography>
                <Typography variant="body2" color={"text.greyText4"} className="mt-1 fs-12" align="center">012</Typography>
              </Grid>
              <Grid item className="activityItem">
                <Typography variant="body2" className="fs-10 fw-500" align="center">INVOICES</Typography>
                <Typography variant="body2" color={"text.greyText4"} className="mt-1 fs-12" align="center">012</Typography>
              </Grid>
              <Grid item className="activityItem">
                <Typography variant="body2" className="fs-10 fw-500" align="center">BOARDS</Typography>
                <Typography variant="body2" color={"text.greyText4"} className="mt-1 fs-12" align="center">012</Typography>
              </Grid>
              <Grid item className="activityItem">
                <Typography variant="body2" className="fs-10 fw-500" align="center">FLOWS</Typography>
                <Typography variant="body2" color={"text.greyText4"} className="mt-1 fs-12" align="center">012</Typography>
              </Grid>
              <Grid item className="activityItem">
                <Typography variant="body2" className="fs-10 fw-500" align="center">DOCS</Typography>
                <Typography variant="body2" color={"text.greyText4"} className="mt-1 fs-12" align="center">012</Typography>
              </Grid>

            </Grid>
          </Grid>
        </Grid> */}
        {/* Activities */}

        {/* <Divider sx={{ mt: 5, mb: 5, width: '100%' }} /> */}

        {/*  User in Other Agency */}
        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>User in Other Agency</Typography>
            <Typography variant="body2" className="fw-500 pr-3" color={"text.greyText4"}>The above user might be a member in other agency as client, user & admin. Here we can change the user role</Typography>
          </Grid>
          <Grid item xs={12} md={8}>

            <Grid container justifyContent={"space-between"} alignItems={"center"} className="otherAgenciesHeader">
              <Typography variant="body2" className="fw-500 uppercase" color="text.greyText5">Agency Name</Typography>
              <Typography variant="body2" className="fw-500 uppercase" color="text.greyText5">USer Role</Typography>
            </Grid>

            {/* Loop */}
            {
              workspace.slice(0, workspaceCount).map((item) => {
                const userType = role[item.id]?.type
                return (
                  <Grid container justifyContent={"space-between"} alignItems={"center"} className="otherAgenciesItem">
                    <Grid className="dflex aCenter">

                      {item.agency_logo ? <img src={item.agency_logo} width="30px" height="30px" alt="" /> : <Avatar />}

                      <Grid className="pl-1">
                        <Typography variant="body2" className="fw-600">{item.agency_name}</Typography>
                        <Typography variant="body2" color={"text.greyText4"} className="dflex mt5 fs-10"><BuildingIcon /><span className="ml-1">{item.category}</span></Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="body2" className="fs-12 500 dflex" color={"text.greyText1"}><UserIcon /><span className="ml-1">{userType}</span></Typography>
                  </Grid>
                )
              })
            }
            {/* Loop */}

            {workspace.length - 1 >= 6 ? <Typography className="jEnd dflex primary1 cursorPointer" onClick={() => { workspaceCount === 6 ? setWorkspaceCount(workspace?.length) : setWorkspaceCount(6) }} > {workspaceCount === 6 ? `Show more (${workspace?.length - 6})` : "Show Less"}</Typography>
              : ""}

          </Grid>
        </Grid>
        {/* User in Other Agency  */}

      </Grid>
    </Grid>
  );
};

// default props
UserInfo.defaultProps = {
  classes: {}
};

// prop types
UserInfo.propTypes = {
  classes: PropTypes.object
};


export default styled(UserInfo)(Style);
