import { postRequest } from "../../utils/apiHelper";

export const agenciesService = {
  getAgencies,
  getAgenciesDetail,
  getAgenciPayment,
  getAgenciesClient,
  getAgencyFlows,
  getCustomerPayement,
  getUser,
  getUserInfo,
  getInvoice,
};

async function getAgencies(data) {
  return await postRequest(`agency/${data?.queryParams}`, data.filters);
}

async function getAgenciesDetail(queryParams = "") {
  return await postRequest(`agency/agenciesdetail/${queryParams}`);
}

async function getAgenciPayment(queryParams = "") {
  return await postRequest(`agency/agencypayment/${queryParams}`);
}

async function getAgenciesClient(queryParams = "") {
  return await postRequest(`agency/agencyclients/${queryParams}`)
}

async function getAgencyFlows(queryParams = "") {
  return await postRequest(`agency/agencyflows/${queryParams}`)
}

async function getCustomerPayement(queryParams = "") {
  return await postRequest(`agency/customerpayments${queryParams}`);
}

async function getUser(queryParams = "") {
  return await postRequest(`agency/users/${queryParams}`);
}

async function getUserInfo(queryParams = "") {
  return await postRequest(`agency/user/${queryParams}`);
}

async function getInvoice(data) {
  console.log(data?.queryParams, "8888")
  return await postRequest(`agency/invoice/${data?.queryParams}`, data.filters);
}
