import { Grid, Typography, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { rulesToParams } from "../../../components/Filter/filterUtil";
import Table from "../../../components/Table";
import Style from "../Style";
import { getAgenciesFlows } from "../../../store/actions/agenciesAction";
import {
    addFilterRule,
    applyFilter,
    clearFilter,
    removeFilterRule,
    setOrderDirection,
    setOrdering,
    setPage,
    setPageSize,
    setSearch,
    updateFilterRule,
} from "../../../store/reducers/agenciesFlowsReducer";

const AgencyFlows = (props) => {
    const { className } = props;
    const dispatch = useDispatch();
    let { agency_id } = useParams();
    console.log("agency_id1111", agency_id);

    const { list } = useSelector((state) => state.agenciesFlows);
    const { pagination, search, ordering, orderBy, filter, orderDirection } =
        list;

    // useEffect(() => {
    //   dispatch(getAgenciesClient(agency_id));
    // }, [dispatch, agency_id]);

    const getAgenciesFlowsDetails = (
        page_size,
        page_number,
        search,
        ordering,
        orderDirection
    ) => {
        let queryParams = "?";

        queryParams += `page_size=${page_size}`;

        queryParams += `&page=${page_number}`;

        if (search) {
            queryParams += `&search=${search}`;
        }

        if (ordering) {
            queryParams += `&ordering=${ordering}&order_direction=${orderDirection}`;
        }

        dispatch(getAgenciesFlows(`${agency_id}${queryParams}`));
    };

    const handlePageSize = (e) => {
        getAgenciesFlowsDetails(
            e.target.value,
            pagination.page_number,
            search,
            ordering,
            orderDirection
        );
        dispatch(setPageSize(e.target.value));
    };

    const handlePagination = (e, pageValue) => {
        getAgenciesFlowsDetails(pagination.page_size, pageValue, search, ordering);
        dispatch(setPage(pageValue));
    };

    const handleSearch = (searchVal) => {
        getAgenciesFlowsDetails(
            pagination.page_size,
            pagination.page_number,
            searchVal,
            ordering,
            orderDirection
        );
        dispatch(setSearch(searchVal));
    };

    const onSort = (sortBy) => {
        const currentOrderDirection =
            ordering === sortBy && orderBy === "asc" ? "desc" : "asc";
        getAgenciesFlowsDetails(
            pagination.page_size,
            pagination.page_number,
            search,
            sortBy,
            currentOrderDirection,
            orderDirection
        );
        dispatch(setOrdering(sortBy));
        dispatch(setOrderDirection(currentOrderDirection));
    };

    const onFilter = (reason, data) => {
        switch (reason) {
            case "add":
                dispatch(addFilterRule());
                break;
            case "remove":
                dispatch(removeFilterRule(data));
                break;
            case "update":
                dispatch(updateFilterRule(data));
                break;
            case "apply":
                dispatch(applyFilter(rulesToParams(filter.rules)));
                break;
            case "clear":
                dispatch(clearFilter());
                break;
            default:
                return;
        }
    };

    useEffect(() => {
        getAgenciesFlowsDetails(
            pagination.page_size,
            pagination.page_number,
            search,
            ordering
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agency_id]);

    const headers = [
        { field: "job_title", label: "Flow Name" },
        { field: "job_due_date", label: "Due Date" },
        { field: "username", label: "Created By" },
        { field: "status", label: "Status" },
        // { field: "avatar_filename", label: "file" },
    ];

    const getStatusLabel = (status) => {
        switch (status) {
            case 0:
                return "In Progress";
            case 1:
                return "Completed";
            default:
                return "Unknown";
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 0:
                return { color: "#FD7E01", fontWeight: "500" };
            case 1:
                return { color: "#0E8E2A", fontWeight: "500" };
            default:
                return "grey";
        }
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
        }).format(date);
    };

    return (
        <Grid container className={className}>
            <Grid className="dflex mt-5">
                <Table
                    title={"Agency Flows"}
                    headers={headers}
                    data={
                        list?.results.map((item) => ({
                            ...item,
                            job_title: (
                                <Typography style={{ fontWeight: "bold", color: "black" }}>
                                    {item.job_title}
                                </Typography>
                            ),
                            job_due_date: formatDate(item.job_due_date),
                            status: (
                                <span style={getStatusColor(item.status)}>
                                    {getStatusLabel(item.status)}
                                </span>
                            ),
                            avatar_filename: (
                                <img
                                    src={`path_to_your_assets/${item.avatar_filename}`}
                                    alt="Avatar"
                                    style={{ width: 50, height: 50, borderRadius: "50%" }}
                                />
                            ),
                            username: item.username ? item.username : "-",
                        })) || []
                    }
                    search={search}
                    pagination={pagination}
                    handlePagination={handlePagination}
                    handlePageSize={handlePageSize}
                    handleSearch={handleSearch}
                    ordering={ordering}
                    orderBy={orderBy || []}
                    onSort={onSort}
                    filter={filter}
                    onFilter={onFilter}
                />
                {/* <Grid>{loading ? "Loading..." : ""}</Grid> */}
            </Grid>
        </Grid>
    );
};

AgencyFlows.defaultProps = {};

AgencyFlows.propTypes = {};

export default styled(AgencyFlows)(Style);