import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import AgencyList from "../containers/Agencies/AgencyList";
import AgencyPayment from "../containers/Agencies/AgencyPayment";
import AgencyDashboard from "../containers/Agencies";
import UserInfo from "../containers/Agencies/UserInfo";
import AgecyUsers from "../containers/Agencies/Users";
import Invoices from "../containers/Invoices";
import AgencyLayout from "../layouts/AgencyLayout";
import AgencyFlows from "../containers/Agencies/AgencyFlows";
import AgencyClient from "../containers/Agencies/AgencyClient";

const agenciesRoutes = [
  {
    path: "/agencies",
    element: <ProtectedRoute />,
    children: [
      {
        path: "",
        element: <AgencyDashboard />,
      },
      {
        path: "list",
        element: <AgencyList />,
      },
      {
        path: ":agency_id",
        element: <AgencyLayout />,
        children: [
          {
            path: "",
            element: <AgencyPayment />
          },
          {
            path: "clients",
            element: <AgencyClient />,
          },
          {
            path: "flows",
            element: <AgencyFlows />
          }
        ]
      },
      {
        path: "users",
        element: <AgecyUsers />,
      },
      {
        path: "invoice",
        element: <Invoices />,
      },
      {
        path: "user/:id",
        element: <UserInfo />,
      },
    ],
  },
];

export default agenciesRoutes;
