import { Grid, Typography, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { rulesToParams } from "../../../components/Filter/filterUtil";
import Table from "../../../components/Table";
import { getAgenciesClient } from "../../../store/actions/agenciesAction";
import {
    addFilterRule,
    applyFilter,
    clearFilter,
    removeFilterRule,
    setOrderDirection,
    setOrdering,
    setPage,
    setPageSize,
    setSearch,
    updateFilterRule,
} from "../../../store/reducers/agenciesClientReducer";
import Style from "../Style";

const AgencyClients = (props) => {
    const { className } = props;
    const dispatch = useDispatch();
    let { agency_id } = useParams();

    const { loading, list } = useSelector((state) => state.agenciesClient);
    const { pagination, search, ordering, orderBy, orderDirection, filter } =
        list;

    // useEffect(() => {
    //   dispatch(getAgenciesClient(agency_id));
    // }, [dispatch, agency_id]);

    const getAgenciesClientDetails = (
        page_size,
        page_number,
        search,
        ordering,
        orderDirection
    ) => {
        let queryParams = "?";

        queryParams += `page_size=${page_size}`;

        queryParams += `&page=${page_number}`;

        if (search) {
            queryParams += `&search=${search}`;
        }

        if (ordering) {
            queryParams += `&ordering=${ordering}&order_direction=${orderDirection}`;
        }

        dispatch(getAgenciesClient(`${agency_id}${queryParams}`));
    };

    const handlePageSize = (e) => {
        getAgenciesClientDetails(
            e.target.value,
            pagination.page_number,
            search,
            ordering,
            orderDirection
        );
        dispatch(setPageSize(e.target.value));
    };

    const handlePagination = (e, pageValue) => {
        getAgenciesClientDetails(
            pagination.page_size,
            pageValue,
            search,
            ordering,
            orderDirection
        );
        dispatch(setPage(pageValue));
    };

    const handleSearch = (searchVal) => {
        getAgenciesClientDetails(
            pagination.page_size,
            pagination.page_number,
            searchVal,
            ordering,
            orderDirection
        );
        dispatch(setSearch(searchVal));
    };

    const onSort = (sortBy) => {
        const currentOrderDirection =
            ordering === sortBy && orderBy === "asc" ? "desc" : "asc";
        getAgenciesClientDetails(
            pagination.page_size,
            pagination.page_number,
            search,
            sortBy,
            currentOrderDirection,
            orderDirection
        );
        dispatch(setOrdering(sortBy));
        dispatch(setOrderDirection(currentOrderDirection));
    };

    const onFilter = (reason, data) => {
        switch (reason) {
            case "add":
                dispatch(addFilterRule());
                break;
            case "remove":
                dispatch(removeFilterRule(data));
                break;
            case "update":
                dispatch(updateFilterRule(data));
                break;
            case "apply":
                dispatch(applyFilter(rulesToParams(filter.rules)));
                break;
            case "clear":
                dispatch(clearFilter());
                break;
            default:
                return;
        }
    };

    useEffect(() => {
        getAgenciesClientDetails(
            pagination.page_size,
            pagination.page_number,
            search,
            ordering,
            orderDirection
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agency_id]);

    const headers = [
        { field: "client_name", label: "Client Name" },
        { field: "status", label: "Status" },
        { field: "username", label: "Created By" },
    ];

    const getStatusLabel = (status) => {
        switch (status) {
            case 0:
                return "In Progress";
            case 1:
                return "Completed";
            default:
                return "Unknown";
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 0:
                return "#FD7E01";
            case 1:
                return "#0E8E2A";
            default:
                return "grey";
        }
    };

    return (
        <Grid container className={className}>
            <Grid className="dflex mt-5">
                <Table
                    title={"Agency Client"}
                    headers={headers}
                    // data={list?.results || []}
                    data={
                        list?.results.map((item) => ({
                            ...item,
                            client_name: (
                                <Typography style={{ fontWeight: "bold", color: "black" }}>
                                    {item.client_name}
                                </Typography>
                            ),
                            status: (
                                <span style={{ color: getStatusColor(item.status) }}>
                                    {getStatusLabel(item.status)}
                                </span>
                            ),
                            username: item.username ? item.username : "-",
                        })) || []
                    }
                    search={search}
                    pagination={pagination}
                    handlePagination={handlePagination}
                    handlePageSize={handlePageSize}
                    handleSearch={handleSearch}
                    ordering={ordering}
                    orderBy={orderBy || []}
                    onSort={onSort}
                    filter={filter}
                    onFilter={onFilter}
                />
            </Grid>
            <Grid>{loading ? "Loading..." : ""}</Grid>
        </Grid>
    );
};

AgencyClients.defaultProps = {};

AgencyClients.propTypes = {};

export default styled(AgencyClients)(Style);