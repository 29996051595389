import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Text from "../Text";
import { FilterIcon } from "../../assets/svg";


const TableHeader = (props) => {
  const {
    title,
    search,
    onSearch,
    onFilter,
    filter
  } = props;

  return (
    <Grid className="th-wrapper" style={{ height: 70 }}>
      <h2 className="th-title">{title}</h2>
      <Grid style={{ display: "flex" }}>
        <Text
          className="th-search"
          placeholder={"Search"}
          defaultValue={search}
          StartIcon={SearchIcon}
          onSearch={onSearch}
        />


        {/* **** Coming Soon ***** */}

        {filter &&
          <Grid style={{ marginLeft: "12px" }} className=" dflex aCenter cursorPointer" onClick={() => { onFilter() }}>
            <Button>
              <FilterIcon />
              <Typography style={{ color: "#9DA9BA" }} className="pl-1">Filter</Typography>
            </Button>
          </Grid>}
      </Grid>
    </Grid>
  );
};

export default TableHeader;
