import { createSlice } from "@reduxjs/toolkit";
import { getUserInfo } from "../actions/agenciesAction";


const initialState = {
    list: {
        userInfo: [],
        ownedAgencies: [],
        workspace: [],
    },
};

export const userInfoSlice = createSlice({
    name: "UsersInfo",
    initialState,
    extraReducers: (builder) => {
        // add cases for getUserInfo api calls
        builder
            .addCase(getUserInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserInfo.fulfilled, (state, { payload }) => {
                state.list = {
                    ...state.list,
                    ...payload,

                };
                state.loading = false;
            })
            .addCase(getUserInfo.rejected, (state) => {
                state.loading = false;
            });
        // add cases for other api calls
        // like builder.addCase

    },
});


export default userInfoSlice.reducer;
