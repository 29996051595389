import React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Style from "./style";
import {
  MarketingIcon,
  ReturnIcon,
  SalesIcon,
  TotalRevenuIcon,
} from "../../assets/svg";
import {
  setPageSize, setPage,
  setSearch,
  setOrdering,
  addFilterRule,
  updateFilterRule,
  removeFilterRule,
  applyFilter,
  clearFilter,
} from "../../store/reducers/customPaymentReducer";
import { rulesToParams } from "../../components/Filter/filterUtil";
import CountCard from "../../components/CountCard";
import { getCustomerPayement } from "../../store/actions/agenciesAction";
import Table from "../../components/Table";

const SalesDashboard = (props) => {
  const { className } = props;
  // const classes = useStyles();

  const dispatch = useDispatch();

  const { loading, list } = useSelector((state) => state.customerpayments);
  const { pagination, search, ordering, orderBy, filter } = list;

  useEffect(() => {
    dispatch(getCustomerPayement());
  }, [dispatch]);

  const StatusButton = ({ text }) => {
    const statusClasses = {
      paid: "paid",
      draft: "draft",
      open: "open",
      void: "void",
    };

    const borderColorClass = statusClasses[text.toLowerCase()] || "default";
    console.log("borderColorClass", borderColorClass);

    return (
      <Grid>
        <Typography className={borderColorClass}>{text}</Typography>
      </Grid>
    );
  };
  const AmountColor = ({ text }) => {
    return (
      <Grid>
        <Typography className="payment">{text}</Typography>
      </Grid>
    );
  };

  const headers = [
    { field: "agency_name", label: "Agency Name" },
    { field: "invoice_amount", label: "amount" },
    { field: "status", label: "status" },
    { field: "expiry", label: "duration" },
    { field: "invoice_id", label: "Invoice no" },
  ];

  const getPaymentsDetails = (page_size, page_number, search, ordering) => {
    let queryParams = "?";

    queryParams += `page_size=${page_size}`;

    queryParams += `&page=${page_number}`;

    if (search) {
      queryParams += `&search=${search}`;
    }

    if (ordering) {
      queryParams += `&ordering=${ordering}`;
    }

    dispatch(getCustomerPayement(`${queryParams}`));
  };

  const handlePageSize = (e) => {
    getPaymentsDetails(
      e.target.value,
      pagination.page_number,
      search,
      ordering
    );
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    getPaymentsDetails(pagination.page_size, pageValue, search, ordering);
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    getPaymentsDetails(
      pagination.page_size,
      pagination.page_number,
      searchVal,
      ordering
    );
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    const currentOrderDirection =
      ordering === sortBy && orderBy === "asc" ? "desc" : "asc";
    getPaymentsDetails(
      pagination.page_size,
      pagination.page_number,
      search,
      sortBy,
      currentOrderDirection
    );
    dispatch(setOrdering(sortBy));
  };

  const onFilter = (reason, data) => {
    switch (reason) {
      case "add":
        dispatch(addFilterRule());
        break;
      case "remove":
        dispatch(removeFilterRule(data));
        break;
      case "update":
        dispatch(updateFilterRule(data));
        break;
      case "apply":
        dispatch(applyFilter(rulesToParams(filter.rules)));
        break;
      case "clear":
        dispatch(clearFilter());
        break;
      default:
        return;
    }
  };

  //topCard
  const countdata = [
    {
      name: "Sales",
      count: "$1,234.00",
      icon: <SalesIcon />,
      percentage: 12,
    },
    {
      name: "Total Revenue",
      count: "$10,566.01",
      icon: <TotalRevenuIcon />,
      percentage: 35,
    },
    {
      name: "Return",
      count: "$956.00",
      icon: <ReturnIcon />,
      percentage: -5,
    },
    {
      name: "Marketing",
      count: "5,566.01",
      icon: <MarketingIcon />,
      percentage: 15,
    },
  ];

  return (
    <Grid className={className}>
      <Grid container alignItems={"center"} className={className} spacing={3}>
        <CountCard data={countdata} />
      </Grid>
      <Grid className="tableContainer">
        <Table
          title={"Customer Payments"}
          headers={headers}
          data={list?.results || []}
          search={search}
          pagination={pagination}
          handlePagination={handlePagination}
          handlePageSize={handlePageSize}
          handleSearch={handleSearch}
          ordering={ordering}
          orderBy={orderBy || []}
          onSort={onSort}
          filter={filter}
          onFilter={onFilter}
          cellMap={{
            status: ({ rowData }) => {
              console.log("row.invoice_id1112:", rowData.status);
              return <StatusButton text={rowData.status} />;
            },
            invoice_amount: ({ rowData }) => {
              console.log("row.112:", rowData.amount);
              return <AmountColor text={rowData.invoice_amount} />;
            },
          }}
        />
        <div>{loading ? "Loading..." : ""}</div>
      </Grid>
    </Grid>
  );
};

// default props
SalesDashboard.defaultProps = {
  classes: {},
};

// prop types
SalesDashboard.propTypes = {
  classes: PropTypes.object,
};

export default styled(SalesDashboard)(Style);
