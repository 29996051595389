import React from "react";
import PropTypes from 'prop-types';
import {
  Avatar,
  Grid,
  Typography,
  styled,
  Button,
} from "@mui/material";
import Style from "./style";

import { PDFDownloadIcon } from "../../../assets/svg";

const InvoiceDetail = (props) => {
  const { className } = props;

  //topCard

  return (
    <Grid
      container
      alignItems={"center"}
      className={className}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"} className="mb-2">
        <Typography variant="h4" color="text.primaryText">Detail Page</Typography>
        <Grid className="dflex aCenter">
          {/* <TextField size="small"
          placeholder="Search here..."
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
          />
          <Button variant="text" size="small" color="secondary" startIcon={<DownloadIcon />} className="ml-1">Download</Button> */}
        </Grid>
      </Grid>
      <Grid container className="invoiceContainer">
        <Grid item xs={12} align="center" sx={{ mb: 3 }} className="logoContainer">
          <Avatar />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={"space-between"} className="pb-1">
            <Grid>
              <Typography variant="body1" color={"text.greyText1"}>Date</Typography>
              <Typography variant="body1" color={"text.primaryText"} className="fw-600">21st Jun, 2023</Typography>
            </Grid>
            <Grid>
              <Typography variant="body1" color={"text.greyText1"}>Invoice</Typography>
              <Typography variant="body1" color={"text.primaryText"} className="fw-600">#1817</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"}>
            <Grid>
              <Typography variant="body1" color={"text.greyText1"}>Invoice to</Typography>
              <Typography variant="h4" color={"text.primaryText"} className="fw-600">gaurav</Typography>
            </Grid>
            <Grid>
              <Typography variant="body1" color={"text.greyText1"}>Invoice</Typography>
              <Typography variant="h4" color={"text.primaryText"} className="fw-600">#1817</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <Grid container className="headerRow">
            <Grid item sx={{ flex: 2 }}><Typography variant="body1" color="text.greyText1">Description</Typography></Grid>
            <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.greyText1">Qty</Typography></Grid>
            <Grid item sx={{ flex: 1 }}><Typography align="right" variant="body1" color="text.greyText1">Price(INR)</Typography></Grid>
            <Grid item sx={{ flex: 1 }}><Typography align="right" variant="body1" color="text.greyText1">Total(INR)</Typography></Grid>
          </Grid>
          {/* Loop */}
          <Grid container className="bodyRow">
            <Grid item sx={{ flex: 2 }}><Typography variant="body1" color="text.primaryText" className="fw-500">Description</Typography></Grid>
            <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.primaryText" className="fw-500">Qty</Typography></Grid>
            <Grid item sx={{ flex: 1 }}><Typography align="right" variant="body1" color="text.primaryText" className="fw-500">Price(INR)</Typography></Grid>
            <Grid item sx={{ flex: 1 }}><Typography align="right" variant="body1" color="text.primaryText" className="fw-500">Total(INR)</Typography></Grid>
          </Grid>
          {/* Loop */}
        </Grid>

        <Grid container sx={{ mt: 3 }}>
          <Grid item sx={{ flex: 4 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">Total</Typography></Grid>
          <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">₹1,000.00</Typography></Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item sx={{ flex: 4 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">Discount(0%):</Typography></Grid>
          <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">₹0.00</Typography></Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item sx={{ flex: 4 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">VAT(20%)</Typography></Grid>
          <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">₹200.00</Typography></Grid>
        </Grid>

        <Grid container className="footerRow" sx={{ mt: 2 }}>
          <Grid item sx={{ flex: 4 }} className="dflex jEnd aBaseline">
            <Typography variant="body1" color="text.primaryText" className="fw-700" align="right">Amount Due /</Typography>
            <Typography variant="body2" color="text.greyText" className="fw-500" align="right">INR</Typography>
          </Grid>
          <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.primaryText" className="fw-700" align="right">₹1,200.00</Typography></Grid>
        </Grid>

        <Grid container justifyContent="space-between" alignItems="end">
          <Grid item>
            <Typography variant="body1" color="text.primaryText" className="fw-600 pb5">VAT182828282</Typography>
            <Grid item className="dflex aBaseline pb5">
              <Typography variant="body1" color="text.greyText1" className="fw-500">Sort-code:</Typography>
              <Typography variant="body1" color="text.primaryText" className="fw-600">84849499404</Typography>
            </Grid>
            <Grid item className="dflex aBaseline pb5">
              <Typography variant="body1" color="text.greyText1" className="fw-500">Account number:</Typography>
              <Typography variant="body1" color="text.primaryText" className="fw-600">44849</Typography>
            </Grid>
            <Typography variant="body1" color="text.primaryText" className="fw-600 pb5">IBAN 409843974</Typography>
            <Typography variant="body1" color="text.primaryText" className="fw-600">Thanks a lot</Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="secondary" startIcon={<PDFDownloadIcon />}>Download PDF Invoice</Button>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
};

InvoiceDetail.defaultProps = {
  classes: {}
};

InvoiceDetail.propTypes = {
  classes: PropTypes.object
};

export default styled(InvoiceDetail)(Style);
