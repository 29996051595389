let API_POINTS = {
    url: "http://localhost:5000/api/v1",
};

const SERVER_POINT = process.env.REACT_APP_BUILD_TO || "LOCAL"; // QA, UAT, PROD, LOCAL

switch (SERVER_POINT) {
    case "PROD":
        API_POINTS = {
            url: "https://server.flozy.com/api/v1/",
        };
        break;
    case "QA":
        API_POINTS = {
            url: "https://qa-supportserver.flozy.com/api/v1/",
        };
        break;
    default:
        API_POINTS = {
            url: "http://localhost:5000/api/v1/",
        };
        break;
}

console.log(API_POINTS)

export const _api = API_POINTS;
