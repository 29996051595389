import {
  MenuHome,
  MenuAgency,
  MenuSales,
  MenuUser,
  MenuSttings,
  InvoiceIcon,
  AgencyListIcon,
  UsersIcon1,
  MailIcon1,
} from "../../assets/svg/index";

export const MenuDatas = [
  {
    icon: <MenuHome />,
    name: "Home",
    link: "",
  },
  {
    icon: <MenuAgency />,
    name: "Agency Dashboard",
    link: "/agencies",
    permission: (p) => Object.values(p?.agencies || []).indexOf(true) >= 0,
    children: [
      {
        icon: <AgencyListIcon />,
        name: "Agencies List",
        link: "/agencies/list",
        permission: (p) => p?.agencies?.agencies_list === true,
      },
      {
        icon: <UsersIcon1 />,
        name: "Users",
        link: "/agencies/users",
        permission: (p) => p?.agencies?.users_list === true,
      },
      {
        icon: <InvoiceIcon />,
        name: "Invoice",
        link: "/agencies/invoice",
        permission: (p) => p?.agencies?.users_list === true,
      },
    ],
  },
  {
    icon: <MenuSales />,
    name: "Sales Dashboard",
    link: "/sales",
    permission: (p) => Object.values(p?.sales || []).indexOf(true) >= 0,
    children: [
      {
        icon: <MenuUser />,
        name: "Customer payments",
        link: "/sales/customerpayments",
        permission: (p) => p?.sales?.payment_list === true,
      },
      {
        icon: <MenuUser />,
        name: "Reports",
        link: "",
        permission: (p) => p?.sales?.reports_list === true,
      },
    ],
  },
  {
    icon: <MailIcon1 />,
    name: "Email Campaign ",
    link: "",
  },
  {
    icon: <MenuUser />,
    name: "User Management",
    link: "/users",
    permission: (p) => Object.values(p?.sales_users || []).indexOf(true) >= 0,
    children: [
      {
        icon: <MenuUser />,
        name: "Users Group",
        link: "",
        permission: (p) => p?.sales_users?.group_list === true,
      },
    ],
  },
  {
    icon: <MenuSttings />,
    name: "Settings",
    link: "",
  },
];
