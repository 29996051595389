import React, { useEffect } from "react";
import { styled, Avatar, Grid, Button, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getAgencies } from "../../store/actions/agenciesAction";
import moment from 'moment';
import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
  applyFilter,
  clearFilter,
} from "../../store/reducers/agenciesReducer";
import Table from "../../components/Table";

const styles = {
  ".avatar-cell": {
    display: "flex",
    alignItems: "center",
    ".avatar-title": {
      marginLeft: "8px",
    },
  },
};

const name = (props) => {
  const { rowData } = props;
  return (
    <Grid className="flexAlign">
      {rowData?.agency_logo ?
        <img src={rowData?.agency_logo} height="30px" width="30px" style={{ borderRadius: "100%" }} alt="" /> : <Avatar style={{ height: "30px", width: "30px" }} />
      }
      <Typography className="pl-1" style={{ color: "#0F172A" }}>{rowData?.name}</Typography>
    </Grid>
  )
};

const CreatedOn = (props) => {
  const { rowData } = props;
  const val = moment(rowData.created_on).format('DD-MM-YYYY');
  return val
}

const Expiry = (props) => {
  const { rowData } = props;
  const val = moment(rowData.expiry).format('DD-MM-YYYY');
  return val
}

const Action = () => {
  return <Button className="moreOption" >view</Button>
}

const Status = (props) => {
  const { rowData } = props;
  return (
    <div>
      {rowData.status === "Deleted" ? <StatusButton lable={"Deleted"} style={{ background: "#9DA9BA", color: "#FFFFFF" }} /> :

        (rowData.status === "Cancelled") ? <StatusButton lable={"Cancelled"} style={{ background: "#FFDFDD", color: "#FF3B3B" }} /> :

          (rowData.status === "Trial") ? <StatusButton lable={"Trial"} style={{ background: "#D6DFFF", color: "#7866E4" }} /> :

            (rowData.status === "Active") ? <StatusButton lable={"Active"} style={{ background: "#D4F6D2", color: "#0E8E2A" }} />

              : <StatusButton lable={"Inactive"} style={{ background: "#EDEDED", color: "#64748B" }} />}
    </div>
  );
};
const StatusButton = (props) => {
  const { lable, style } = props;
  return (
    <Grid>
      <Button style={{ ...style, minWidth: "86px" }}>
        {lable}
      </Button>
    </Grid>
  )
}

const cellMap = {
  name: name,
  created_on: CreatedOn,
  expiry: Expiry,
  action: Action,
  status: Status
};

const AgencyList = (props) => {
  const { className } = props;
  const { list } = useSelector((state) => state.agencies);
  const { pagination, search, ordering, orderBy, filter } = list;

  const dispatch = useDispatch();

  useEffect(() => {
    let keysearch = "";
    let status = filter?.Status;
    let price = filter?.Price;

    let filters = { status, price, ordering }

    if (search) {
      keysearch = `&search=${search}`;
    }
    const queryParams = `?page_size=${pagination?.page_size}&page=${pagination?.page_number}${keysearch}`
    dispatch(
      getAgencies(
        { queryParams, filters }
      )
    );
  }, [
    dispatch,
    pagination?.page_size,
    pagination?.page_number,
    search,
    ordering,
    filter,
  ]);



  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    dispatch(setOrdering(sortBy));
  };

  const handleFilter = (data) => {
    dispatch(applyFilter(data));
  };

  const removeFilter = (data) => {
    dispatch(clearFilter());
  };

  const options = [
    {
      title: "Status",
      type: "checkbox",
      option: [
        { value: "Active" },
        { value: "Trial" },
        { value: "Inactive" },
        { value: "Cancelled" },
        { value: "Deleted" },
      ]
    },
    {
      title: "Price",
      type: "price",
    }
  ];



  return (
    <div className={`${className} h-100`}>
      <Table
        title={"Agencies List"}
        headers={[...(list?.headers || []), { field: 'action', label: 'action' }]}
        data={list?.results || []}
        cellMap={cellMap}
        search={search}
        handleSearch={handleSearch}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        ordering={ordering}
        orderBy={orderBy || []}
        onSort={onSort}
        filter={true}
        handleFilter={handleFilter}
        removeFilter={removeFilter}
        options={options}
      />
    </div>
  );
};

export default styled(AgencyList)(styles);
