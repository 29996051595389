import uiReducer from "./uiReducer";
import agenciesReducer from "./agenciesReducer";
import authReducer from "./authReducer";
import agencyUserReducer from "./agencyUserReducer";
import agencyUserInfoReducer from "./agencyUserInfoReducer";
import agencyInvoiceReducer from "./agencyInvoiceReducer";
import userManagementReducer from "./userManagementReducer";
import agenciesClientReducer from "./agenciesClientReducer";
import agenciesFlowsReducer from "./agenciesFlowsReducer";
import customPaymentReducer from "./customPaymentReducer";
import agenciPaymentReducer from "./agenciPaymentReducer";

const reducers = {
    agencies: agenciesReducer,
    auth: authReducer,
    ui: uiReducer,
    user: agencyUserReducer,
    userInfo: agencyUserInfoReducer,
    invoice: agencyInvoiceReducer,
    userManagement: userManagementReducer,
    agenciesClient: agenciesClientReducer,
    agenciesFlows: agenciesFlowsReducer,
    agenciesPayment: agenciPaymentReducer,
    customerpayments: customPaymentReducer,
}

export default reducers