import theme from "../../../theme";

const Style = () => ({
    '.invoiceContainer': {
        padding: '50px',
        borderRadius: '20px',
        boxShadow: '4px 4px 25px 0px rgba(37, 99, 235, 0.04)',
        background: theme.palette.containers.card
    },
    '.logoContainer': {
        '& .MuiAvatar-root': {
            width: '80px',
            height: '80px'
        }
    },
    '.headerRow': {
        borderTop: '1px solid #EBEBEB',
        borderBottom: '1px solid #EBEBEB',
        padding: '16px 0px'
    },
    '.bodyRow': {
        padding: '16px 0px',
        borderBottom: '1px solid #EBEBEB',
    },
    '.footerRow': {
        borderTop: '1px solid #EBEBEB',
        padding: '16px 0px',
    }
});

export default Style;
