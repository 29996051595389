import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
} from "@mui/material";
import Style from "./style";
import { BackArrowIcon, CloseIcon, DoubleCircle, ViewAllIcon } from "../../assets/svg";
import { useNavigate } from "react-router-dom";

const ShowResultsComponent = (props) => {
    const { className, searchSuggestion, handleAllResults } = props;

    /**
     * define variables
     */

    const navigate = useNavigate();


    return (
        <Grid className={`${className} ${searchSuggestion ? 'searchPopUp' : 'searchPage'}`}>
            <Grid container className="resultsHeader" justifyContent={"space-between"}>
                <Grid className="flexAlign">
                    {
                        !searchSuggestion && <IconButton sx={{ mr: 1, width: '30px', height: '30px', p: '2px' }}>
                            <BackArrowIcon />
                        </IconButton>
                    }

                    <Typography variant="h6" color={"textSecondary"} sx={{ pr: '5px' }}>Showing results for </Typography>
                    <Typography variant="h6" className="fw-600"> “sammy”</Typography>
                </Grid>
                {
                    searchSuggestion &&
                    <Button variant="contained" color="primary" onClick={() => {
                        handleAllResults();
                        navigate(`/results`, { replace: true });
                    }}>Show All Results</Button>
                }
            </Grid>

            <Grid className="resultContainer" sx={{ p: 2 }}>

                {/* table list */}
                <Grid container justifyContent={"space-between"}>
                    <Typography variant="body1" className="resultHeader" color={"primary"}>Invoice</Typography>
                    {
                        !searchSuggestion &&
                        <Button variant="outlined" color="primary" endIcon={<ViewAllIcon />}>View all</Button>
                    }
                </Grid>

                {/* Filters */}
                {
                    !searchSuggestion &&
                    <Grid container className="filterContainer" alignItems={"center"} sx={{ mt: 2 }}>
                        <Typography variant="body1" className="fw-500" color={"textSecondary"} sx={{ mr: 2 }}>Filters :</Typography>

                        <Grid className="filteredItem">
                            <DoubleCircle />
                            <Typography variant="body1" sx={{ pl: 1 }} className="fw-500" color={"textSecondary"}>Status :</Typography>
                            <Typography variant="body1" sx={{ pl: 1 }} className="fw-500" color={"primary"}>Refunded</Typography>
                            <Grid className="count flexAlign" sx={{ ml: 1 }}>+3</Grid>
                        </Grid>

                        <Button variant="text" className="clearFilter" color="secondary" startIcon={<CloseIcon />} sx={{ ml: 2 }}>Clear Filter</Button>
                    </Grid>
                }

                {/* Filters */}

                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="subtitle" color={"textSecondary"}>Name</Typography></TableCell>
                                <TableCell><Typography variant="subtitle" color={"textSecondary"}>Name</Typography></TableCell>
                                <TableCell><Typography variant="subtitle" color={"textSecondary"}>Name</Typography></TableCell>
                                <TableCell><Typography variant="subtitle" color={"textSecondary"}>Name</Typography></TableCell>
                                <TableCell><Typography variant="subtitle" color={"textSecondary"}>Name</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell><Typography variant="body" color={"grey.light"}>Name</Typography></TableCell>
                                <TableCell><Typography variant="body" color={"grey.light"}>Name</Typography></TableCell>
                                <TableCell><Typography variant="body" color={"grey.light"}>Name</Typography></TableCell>
                                <TableCell><Typography variant="body" color={"grey.light"}>Name</Typography></TableCell>
                                <TableCell><Typography variant="body" color={"grey.light"}>Name</Typography></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* table list */}

            </Grid>

        </Grid>

    );
};

// default props
ShowResultsComponent.defaultProps = {
    searchSuggestion: false
};

// prop types
ShowResultsComponent.propTypes = {
    searchSuggestion: PropTypes.bool,
    handleAllResults: PropTypes.func
};

const ShowResults = styled(ShowResultsComponent)(({ theme }) => Style(theme));

export default ShowResults;
