import theme from "../../theme";

const Style = () => ({
    height: "100%",
    background: theme.palette.containers.body,
    '& .table': {
        ".MuiGrid-root": {
            background: theme.palette.containers.card,
            borderRadius: 12,
        },
    }

})

export default Style;