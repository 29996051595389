import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CheckedIcon,
  CopyIcon,
  FilterRefresh,
  UnCheckedIcon
} from "../../../assets/svg";
import Table from "../../../components/Table";
import { getAgenciesPayment } from "../../../store/actions/agenciesAction";
import {
  applyFilter,
  setOrderDirection,
  setOrdering,
  setPage,
  setPageSize,
  setSearch
} from "../../../store/reducers/agenciPaymentReducer";
import Style from "./style";

const AgencyPayment = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  let { agency_id } = useParams();

  const [status, setStatus] = useState(false);
  const [selectDate, setSelectDate] = useState(false);
  const [paymentMode, setPaymentMode] = useState(false);


  const { list } = useSelector((state) => state.agenciesPayment);
  const { pagination, search, ordering, orderBy, orderDirection, filter } = list;

  // const { page_number, page_size } = page;
  const [tableData, setTableData] = useState([]);
  console.log(tableData)

  const subtractOneMonth = (dateString) => {
    if (!dateString) return;
    let inputDate = new Date(dateString);
    inputDate.setMonth(inputDate.getMonth() - 1);

    const formatDate = (date) => {
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
    };

    let formattedDate = formatDate(inputDate);
    return formattedDate;
  };

  // useEffect(() => {
  //   let tabData = data.map((ele) => {
  //     let formattedExpiryDate = new Date(ele.expiry).toLocaleDateString(
  //       "en-GB",
  //       {
  //         day: "2-digit",
  //         month: "long",
  //         year: "numeric",
  //       }
  //     );
  //     let formatCreatedAt = new Date(ele.created_at).toLocaleDateString(
  //       "en-GB",
  //       {
  //         day: "2-digit",
  //         month: "long",
  //         year: "numeric",
  //       }
  //     );
  //     return {
  //       ...ele,
  //       created_at: formatCreatedAt,
  //       expiry: `${subtractOneMonth(ele.expiry)} - ${formattedExpiryDate}`,
  //     };
  //   });
  //   settableData(tabData);
  // }, [data]);

  useEffect(() => {
    if (list.data) {
      let tabData = list.data.map((ele) => {
        let formattedExpiryDate = new Date(ele.expiry).toLocaleDateString(
          "en-GB",
          {
            day: "2-digit",
            month: "long",
            year: "numeric",
          }
        );
        let formatCreatedAt = new Date(ele.created_at).toLocaleDateString(
          "en-GB",
          {
            day: "2-digit",
            month: "long",
            year: "numeric",
          }
        );
        return {
          ...ele,
          created_at: formatCreatedAt,
          expiry: `${subtractOneMonth(ele.expiry)} - ${formattedExpiryDate}`,
        };
      });
      setTableData(tabData);
    }
  }, [list.data]);



  const getInvoicePayments = (
    page_size,
    page_number,
    search,
    ordering,
    orderDirection,
    filter
  ) => {
    let queryParams = "?";

    queryParams += `page_size=${page_size}`;

    queryParams += `&page=${page_number}`;

    if (search) {
      queryParams += `&search=${search}`;
    }

    if (ordering) {
      queryParams += `&ordering=${ordering}&order_direction=${orderDirection}`;
    }

    if (filter) {
      queryParams += `&filter=${filter}`;
    }

    dispatch(getAgenciesPayment(`${agency_id}${queryParams}`));
  };

  const handlePageSize = (e) => {
    getInvoicePayments(
      e.target.value,
      pagination.page_number,
      search,
      ordering,
      orderDirection
    );
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    getInvoicePayments(
      pagination.page_size,
      pageValue,
      search,
      ordering,
      orderDirection
    );
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    getInvoicePayments(
      pagination.page_size,
      pagination.page_number,
      searchVal,
      ordering,
      orderDirection
    );
    dispatch(setSearch(searchVal));
  };


  const onSort = (sortBy) => {
    const currentOrderDirection =
      ordering === sortBy && orderDirection === "asc" ? "desc" : "asc";
    getInvoicePayments(
      pagination.page_size,
      pagination.page_number,
      search,
      filter.params,
      sortBy,
      currentOrderDirection
    );
    dispatch(setOrdering(sortBy));
    dispatch(setOrderDirection(currentOrderDirection));
  };

  const handleFilter = (data) => {
    dispatch(applyFilter(data))
  }

  const removeFilter = (data) => {
    dispatch(removeFilter(data))
  }

  // const onFilter = (reason, data) => {
  //   switch (reason) {
  //     case "add":
  //       dispatch(addFilterRule());
  //       break;
  //     case "remove":
  //       dispatch(removeFilterRule(data));
  //       break;
  //     case "update":
  //       dispatch(updateFilterRule(data));
  //       break;
  //     case "apply":
  //       dispatch(applyFilter(rulesToParams(filter.rules)));
  //       break;
  //     case "clear":
  //       dispatch(clearFilter());
  //       break;
  //     default:
  //       return;
  //   }
  // };



  const headers = [
    { field: "invoice_amount", label: "Amount" },
    { field: "status", label: "Status" },
    { field: "created_at", label: "Created On" },
    { field: "expiry", label: "duration" },
    { field: "invoice_id", label: "Invoice no" },
  ];

  // useEffect(() => {
  //   dispatch(getAgenciesPayment(agency_id));
  // }, [dispatch, agency_id]);
  useEffect(() => {
    getInvoicePayments(
      pagination.page_size,
      pagination.page_number,
      search,
      ordering
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency_id]);

  const handleClose = () => {
    setStatus(null);
    setSelectDate(null);
    setPaymentMode(null);
  };

  const statusData = [
    "Paid",
    "Draft",
    "Failed",
    "Refunded",
    "Cancelled",
    "Requested for refund",
    "Deleted",
  ];

  const paymentData = [
    "Card",
    "Digital Payments",
    "echecks",
    "Cash",
    "Paper Check"
  ];

  const Custom = ({ text }) => {
    const [copy, setCopy] = useState(false);
    return (
      <Grid container alignItems="center">
        <Typography variant="body1" style={{ marginRight: 8 }}>
          {text}
        </Typography>
        <CopyToClipboard
          text={text}
          onCopy={() => {
            setCopy(true);
            setTimeout(() => setCopy(false), 2000);
          }}
        >
          <button>
            <CopyIcon />
          </button>
        </CopyToClipboard>
        {copy && <Typography>Copied</Typography>}
      </Grid>
    );
  };

  const StatusButton = ({ text }) => {
    const borderColorClass = text === "paid" ? "paid" : "draft";
    return (
      <Grid>
        <Typography className={borderColorClass}>{text}</Typography>
      </Grid>
    );
  };

  const AmountColor = ({ text }) => {
    return (
      <Grid>
        <Typography className="payment">{text}</Typography>
      </Grid>
    );
  };


  return (
    <Grid container className={className}>
      <Grid item xs={12} className="filter">
        <Typography
          variant="body1"
          className="fw-500 mr-1"
          color="text.greyText1"
        >
          Filters
        </Typography>
        {/* Status */}
        <Grid
          className="filterSelect"
          onClick={(event) => setStatus(event.currentTarget)}
        >
          <FilterRefresh />
          <Typography
            variant="body1"
            className="fw-500 mr-1 ml5"
            color="text.greyText1"
          >
            Status :
          </Typography>
          <Typography variant="body1" className="fw-500" color="text.blueText">
            Refunded
          </Typography>
        </Grid>
        <Popover
          id={Boolean(status) ? "simple-popover" : undefined}
          open={Boolean(status)}
          anchorEl={status}
          onClose={handleClose}
          className={`${className} popover`}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography variant="body1" className="fw-600">
            By Status
          </Typography>
          <Grid className="pt-1">
            {statusData.map((item) => (
              <Grid key={item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<UnCheckedIcon />}
                      checkedIcon={<CheckedIcon />}
                      name={"status"}
                    />
                  }
                  label={item}
                />
              </Grid>
            ))}
          </Grid>
          <Button variant="contained" size="small" className="mt-2" fullWidth>
            Apply
          </Button>
        </Popover>
        {/* Status */}
        {/* Date */}
        <Grid
          className="filterSelect"
          onClick={(event) => setSelectDate(event.currentTarget)}
        >
          <FilterRefresh />
          <Typography
            variant="body1"
            className="fw-500 mr-1 ml5"
            color="text.greyText1"
          >
            Date :
          </Typography>
          <Typography variant="body1" className="fw-500" color="text.blueText">
            June
          </Typography>
        </Grid>
        <Popover
          id={Boolean(selectDate) ? "simple-popover" : undefined}
          open={Boolean(selectDate)}
          anchorEl={selectDate}
          onClose={handleClose}
          className={`${className} popover`}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography variant="body1" className="fw-600">
            By Duration{" "}
          </Typography>
          <Grid>

          </Grid>
          <Button variant="contained" size="small" className="mt-2" fullWidth>
            Apply
          </Button>
        </Popover>
        {/* Date */}
        {/* Payment Mode : */}
        <Grid
          className="filterSelect"
          onClick={(event) => setPaymentMode(event.currentTarget)}
        >
          <FilterRefresh />
          <Typography
            variant="body1"
            className="fw-500 mr-1 ml5"
            color="text.greyText1"
          >
            Payment Mode :
          </Typography>
          <Typography variant="body1" className="fw-500" color="text.blueText">
            Card
          </Typography>
        </Grid>
        <Popover
          id={Boolean(paymentMode) ? "simple-popover" : undefined}
          open={Boolean(paymentMode)}
          anchorEl={paymentMode}
          onClose={handleClose}
          className={`${className} popover`}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography variant="body1" className="fw-600">
            By Payment
          </Typography>
          {paymentData.map((item) => (
            <Grid key={item}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<UnCheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                    name={"status"}
                  />
                }
                label={item}
              />
            </Grid>
          ))}
          <Button variant="contained" size="small" className="mt-2" fullWidth>
            Apply
          </Button>
        </Popover>
        {/* Payment Mode : */}
      </Grid>
      <Grid className="dflex mt-5">
        <Table
          title={"Agency Payments"}
          headers={headers}
          data={list?.results || []}
          pagination={pagination}
          search={search}
          handlePagination={handlePagination}
          handlePageSize={handlePageSize}
          handleSearch={handleSearch}
          ordering={ordering}
          orderBy={orderBy}
          onSort={onSort || []}
          filter={filter}
          onFilter={handleFilter}
          removeFilter={removeFilter}
          cellMap={{
            invoice_id: ({ rowData }) => {
              return <Custom text={rowData.invoice_id} />;
            },
            status: ({ rowData }) => {
              return <StatusButton text={rowData.status} />;
            },
            invoice_amount: ({ rowData }) => {
              return <AmountColor text={rowData.invoice_amount} />;
            },
          }}
        />
      </Grid>

    </Grid>
  );
};

AgencyPayment.defaultProps = {};

AgencyPayment.propTypes = {};

export default styled(AgencyPayment)(Style)
