import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Grid,

  Typography,
  styled,
} from "@mui/material";

// import style
import Style from "./style";
import Table from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../store/actions/agenciesAction";
import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
  addFilterRule,
  updateFilterRule,
  removeFilterRule,
  applyFilter,
  clearFilter,
} from "../../../store/reducers/agencyUserReducer";
import { rulesToParams } from "../../../components/Filter/filterUtil";
import { useNavigate } from "react-router-dom";

const userName = (props) => {
  const { rowData } = props;
  return (
    <Grid className="flexAlign">
      {rowData?.avatar_filename ?
        <img src={rowData?.avatar_filename} height="30px" width="30px" style={{ borderRadius: "100%" }} alt="" /> : <Avatar style={{ height: "30px", width: "30px" }} />
      }
      <Typography className="pl-1" style={{ color: "#0F172A" }}>{rowData?.name}</Typography>
    </Grid>
  )
}

const Action = (props) => {
  const { rowData } = props;
  const navigate = useNavigate()
  return <Button onClick={() => { navigate(`/agencies/user/${rowData?.id}`, { replace: true }); }} className="moreOption">view</Button>
}

const cellMap = {
  name: userName,
  action: Action
};

const AgecyUsers = (props) => {
  const { className } = props;
  const { loading, list } = useSelector((state) => state.user);
  const { pagination, search, ordering, orderBy, filter } = list;
  const dispatch = useDispatch();
  //topCard

  // useEffect(() => {
  //   dispatch(getUser());
  // }, [dispatch]);

  const getUserList = (page_size, page_number, search, ordering) => {
    let queryParams = "?";

    queryParams += `page_size=${page_size}`;

    queryParams += `&page=${page_number}`;

    if (search) {
      queryParams += `&search=${search}`;
    }

    if (ordering) {
      queryParams += `&ordering=${ordering}`;
    }

    dispatch(getUser(`${queryParams}`));
  };

  const handlePageSize = (e) => {
    getUserList(e.target.value, pagination.page_number, search, ordering);
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    getUserList(pagination.page_size, pageValue, search, ordering);
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    getUserList(
      pagination.page_size,
      pagination.page_number,
      searchVal,
      ordering
    );
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    const currentOrderDirection =
      ordering === sortBy && orderBy === "asc" ? "desc" : "asc";
    getUserList(
      pagination.page_size,
      pagination.page_number,
      search,
      sortBy,
      currentOrderDirection
    );
    dispatch(setOrdering(sortBy));
  };

  const onFilter = (reason, data) => {
    switch (reason) {
      case "add":
        dispatch(addFilterRule());
        break;
      case "remove":
        dispatch(removeFilterRule(data));
        break;
      case "update":
        dispatch(updateFilterRule(data));
        break;
      case "apply":
        dispatch(applyFilter(rulesToParams(filter.rules)));
        break;
      case "clear":
        dispatch(clearFilter());
        break;
      default:
        return;
    }
  };
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  return (
    <div className={`${className} h-100`}>
      <Table
        title={"Agency User"}
        headers={[list?.headers || [], { field: 'action', label: 'action' }]}
        data={list?.results || []}
        cellMap={cellMap}
        search={search}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        handleSearch={handleSearch}
        ordering={ordering}
        orderBy={orderBy || []}
        onSort={onSort}
        filter={filter}
        onFilter={onFilter}
      />
      <div>{loading ? "Loading..." : ""}</div>
    </div>
  );
};

AgecyUsers.defaultProps = {
  classes: {},
};

AgecyUsers.propTypes = {
  classes: PropTypes.object,
};

export default styled(AgecyUsers)(Style);
