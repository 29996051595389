const Style = (theme) => ({
    '&.header': {
        height: '80px',
        padding: '10px 20px',
        background: theme.palette.containers.sidebar,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.09)',
        zIndex: 1
    },
    '.searchHeader': {
        maxWidth: '900px',
        '.MuiOutlinedInput-root': {
            height: '40px',
            padding: '9.5px 14px'
        }
    },
    '.suggestListItem': {
        borderRadius: '4px',
        '&.active': {
            background: theme.palette.primary.main,
            '.typeText, .exampleText': {
                color: '#fff'
            }
        },
        '.prefix': {
            height: '22px',
            padding: '0px 10px',
            background: theme.palette.containers.greyBg4,
            marginRight: '8px'
        }
    },

    // show results
    '.resultsHeader': {
        padding: '16px',
        borderBottom: `1px solid ${theme.palette.containers.greyBg4}`
    },
    '.resultHeader': {
        display: 'inline-flex',
        background: theme.palette.containers.blueBg2,
        borderRadius: '5px',
        padding: '4px 10px'
    },
    '&.searchPage': {
        background: theme.palette.containers.card,
        borderRadius: '12px'
    },
    '.filterContainer': {
        boxShadow: '4px 4px 20px 0px #00000008',
        border: '1px solid #EFEFEF',
        borderRadius: '12px',
        padding: '8px 16px'
    },
    '.filteredItem': {
        display: 'inline-flex',
        alignItems: 'center',
        border: `1px solid ${theme.palette.secondary.main}`,
        padding: '5px 12px',
        borderRadius: '7px',
        '.count': {
            height: '20px',
            padding: '2px 6px',
            fontSize: '12px',
            fontWeight: '500',
            background: theme.palette.primary.main,
            color: '#fff',
            borderRadius: '7px',
        },
    },
    '.clearFilter': {
        '.MuiButton-startIcon': {
            padding: '5px',
            borderRadius: '14px',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.containers.greyBg2
        }
    }
})

export default Style;