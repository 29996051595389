export const FlozyLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 48 49" fill="none">
    <path d="M25.9514 15.4137L42.4328 31.8951C43.9135 33.3759 46.4495 32.7405 47.0407 30.7293C47.7369 28.348 48.0739 25.8175 47.9855 23.1931C47.5711 10.6787 37.3829 0.457288 24.8685 0.0152793C11.216 -0.465405 0 10.4577 0 23.9998C0 26.3424 0.337031 28.6077 0.966893 30.7514C1.55255 32.7515 4.08858 33.3648 5.56378 31.8951L22.0452 15.4137C23.1226 14.3363 24.874 14.3363 25.9514 15.4137Z" fill="url(#paint0_linear_7083_44642)" />
    <path d="M20.7691 38.0233L22.1117 36.6807C23.1891 35.6033 24.9406 35.6033 26.018 36.6807L33.51 44.1838C34.2725 44.9463 35.4604 45.1175 36.3831 44.5595C37.1511 44.0954 37.8859 43.5981 38.5931 43.0567C39.9247 42.0345 40.0683 40.0786 38.8804 38.8907L25.9462 25.9565C24.8688 24.8791 23.1173 24.8791 22.0399 25.9565L9.10564 38.8907C7.92326 40.0731 8.05587 42.0235 9.38189 43.0456C13.4318 46.1563 18.4983 48.0072 23.9958 48.0072H24.0234C25.0621 48.0072 25.5815 46.753 24.8467 46.0181L20.7691 41.9296C19.6917 40.8522 19.6917 39.1007 20.7691 38.0233Z" fill="#2F63ED" />
    <defs>
      <linearGradient id="paint0_linear_7083_44642" x1="10.6452" y1="4.05308" x2="44.9" y2="24.9915" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2C63ED" />
        <stop offset="0.994792" stopColor="#8360FD" />
      </linearGradient>
    </defs>
  </svg>
);

export const MailIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path d="M17 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H17C18.1046 15 19 14.1046 19 13V3C19 1.89543 18.1046 1 17 1Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 3L10 9L19 3" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const LockIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
    <path d="M13 9H3C1.89543 9 1 9.89543 1 11V17C1 18.1046 1.89543 19 3 19H13C14.1046 19 15 18.1046 15 17V11C15 9.89543 14.1046 9 13 9Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 15C8.55228 15 9 14.5523 9 14C9 13.4477 8.55228 13 8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15Z" stroke="#64748B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 9V5C4 3.93913 4.42143 2.92172 5.17157 2.17157C5.92172 1.42143 6.93913 1 8 1C9.06087 1 10.0783 1.42143 10.8284 2.17157C11.5786 2.92172 12 3.93913 12 5V9" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const EyeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
    <path d="M2 1L20 19" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.58445 8.58691C9.20917 8.96193 8.99823 9.47067 8.99805 10.0012C8.99786 10.5317 9.20844 11.0406 9.58345 11.4159C9.95847 11.7912 10.4672 12.0021 10.9977 12.0023C11.5283 12.0025 12.0372 11.7919 12.4125 11.4169" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.363 3.36506C9.22042 3.11978 10.1082 2.9969 11 3.00006C15 3.00006 18.333 5.33306 21 10.0001C20.222 11.3611 19.388 12.5241 18.497 13.4881M16.357 15.3491C14.726 16.4491 12.942 17.0001 11 17.0001C7 17.0001 3.667 14.6671 1 10.0001C2.369 7.60506 3.913 5.82506 5.632 4.65906" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const ShowEyeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
    <path d="M9.58445 6.58691C9.20917 6.96193 8.99823 7.47067 8.99805 8.00121C8.99786 8.53175 9.20844 9.04063 9.58345 9.41591C9.95847 9.7912 10.4672 10.0021 10.9977 10.0023C11.5283 10.0025 12.0372 9.79193 12.4125 9.41691" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.363 1.36506C9.22042 1.11978 10.1082 0.9969 11 1.00006C15 1.00006 18.333 3.33306 21 8.00006C20.222 9.36106 19.388 10.5241 18.497 11.4881M16.357 13.3491C14.726 14.4491 12.942 15.0001 11 15.0001C7 15.0001 3.667 12.6671 1 8.00006C2.369 5.60506 3.913 3.82506 5.632 2.65906" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuHome = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M9.33594 14.1807V11.6807" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.72766 1.53021L1.95266 6.15521C1.30266 6.67188 0.885996 7.76355 1.02766 8.58021L2.136 15.2135C2.336 16.3969 3.46933 17.3552 4.66933 17.3552H14.0027C15.1943 17.3552 16.336 16.3885 16.536 15.2135L17.6443 8.58021C17.7777 7.76355 17.361 6.67188 16.7193 6.15521L10.9443 1.53855C10.0527 0.82188 8.611 0.82188 7.72766 1.53021Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuAgency = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M1 17.666H17.6667" stroke="#64748B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.45801 2.66667V17.6667H11.208V2.66667C11.208 1.75 10.833 1 9.70801 1H8.95801C7.83301 1 7.45801 1.75 7.45801 2.66667Z" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.83301 7.66667V17.6667H5.16634V7.66667C5.16634 6.75 4.83301 6 3.83301 6H3.16634C2.16634 6 1.83301 6.75 1.83301 7.66667Z" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5 11.8327V17.666H16.8333V11.8327C16.8333 10.916 16.5 10.166 15.5 10.166H14.8333C13.8333 10.166 13.5 10.916 13.5 11.8327Z" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuSales = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M1 1V15.1667C1 16.55 2.11667 17.6667 3.5 17.6667H17.6667" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.5 13.4974L7.325 9.03074C7.95833 8.29741 9.08333 8.24739 9.76667 8.93906L10.5583 9.73074C11.2417 10.4141 12.3667 10.3724 13 9.63906L16.8333 5.16406" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuEmail = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path d="M17 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H17C18.1046 15 19 14.1046 19 13V3C19 1.89543 18.1046 1 17 1Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 3L10 9L19 3" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuSttings = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path d="M10 11.5C11.3807 11.5 12.5 10.3807 12.5 9C12.5 7.61929 11.3807 6.5 10 6.5C8.61929 6.5 7.5 7.61929 7.5 9C7.5 10.3807 8.61929 11.5 10 11.5Z" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.66699 9.73287V8.26621C1.66699 7.39954 2.37533 6.68287 3.25033 6.68287C4.75866 6.68287 5.37533 5.61621 4.61699 4.30787C4.18366 3.55787 4.44199 2.58287 5.20033 2.14954L6.64199 1.32454C7.30033 0.932873 8.15033 1.16621 8.54199 1.82454L8.63366 1.98287C9.38366 3.29121 10.617 3.29121 11.3753 1.98287L11.467 1.82454C11.8587 1.16621 12.7087 0.932873 13.367 1.32454L14.8087 2.14954C15.567 2.58287 15.8253 3.55787 15.392 4.30787C14.6337 5.61621 15.2503 6.68287 16.7587 6.68287C17.6253 6.68287 18.342 7.39121 18.342 8.26621V9.73287C18.342 10.5995 17.6337 11.3162 16.7587 11.3162C15.2503 11.3162 14.6337 12.3829 15.392 13.6912C15.8253 14.4495 15.567 15.4162 14.8087 15.8495L13.367 16.6745C12.7087 17.0662 11.8587 16.8329 11.467 16.1745L11.3753 16.0162C10.6253 14.7079 9.39199 14.7079 8.63366 16.0162L8.54199 16.1745C8.15033 16.8329 7.30033 17.0662 6.64199 16.6745L5.20033 15.8495C4.44199 15.4162 4.18366 14.4412 4.61699 13.6912C5.37533 12.3829 4.75866 11.3162 3.25033 11.3162C2.37533 11.3162 1.66699 10.5995 1.66699 9.73287Z" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MenuUser = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <path d="M8.15788 9.33333C10.4591 9.33333 12.3245 7.46785 12.3245 5.16667C12.3245 2.86548 10.4591 1 8.15788 1C5.85669 1 3.99121 2.86548 3.99121 5.16667C3.99121 7.46785 5.85669 9.33333 8.15788 9.33333Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1667 12.4492L11.2167 15.3992C11.1 15.5159 10.9917 15.7325 10.9667 15.8909L10.8084 17.0159C10.7501 17.4242 11.0334 17.7075 11.4417 17.6492L12.5667 17.4909C12.725 17.4659 12.9501 17.3575 13.0584 17.2409L16.0084 14.2909C16.5167 13.7826 16.7584 13.1909 16.0084 12.4409C15.2667 11.6992 14.6751 11.9409 14.1667 12.4492Z" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.7422 12.874C13.9922 13.774 14.6922 14.474 15.5922 14.724" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 17.6663C1 14.4413 4.20836 11.833 8.15836 11.833C9.02502 11.833 9.85833 11.958 10.6333 12.1913" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const BellIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
    <path d="M7 3C7 2.46957 7.21071 1.96086 7.58579 1.58579C7.96086 1.21071 8.46957 1 9 1C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3C12.1484 3.54303 13.1274 4.38833 13.8321 5.4453C14.5367 6.50227 14.9404 7.73107 15 9V12C15.0753 12.6217 15.2954 13.2171 15.6428 13.7381C15.9902 14.2592 16.4551 14.6914 17 15H1C1.54494 14.6914 2.00981 14.2592 2.35719 13.7381C2.70457 13.2171 2.92474 12.6217 3 12V9C3.05956 7.73107 3.4633 6.50227 4.16795 5.4453C4.8726 4.38833 5.85159 3.54303 7 3" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 15V16C6 16.7956 6.31607 17.5587 6.87868 18.1213C7.44129 18.6839 8.20435 19 9 19C9.79565 19 10.5587 18.6839 11.1213 18.1213C11.6839 17.5587 12 16.7956 12 16V15" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const SearchIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M8.16667 14.8333C11.8486 14.8333 14.8333 11.8486 14.8333 8.16667C14.8333 4.48477 11.8486 1.5 8.16667 1.5C4.48477 1.5 1.5 4.48477 1.5 8.16667C1.5 11.8486 4.48477 14.8333 8.16667 14.8333Z" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.5003 16.5003L13.167 13.167" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const TotalUsers = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#F8FAFC" />
    <path d="M27.7301 23.0927L27.7302 23.0927L29.4183 23.0927C30.9408 23.0927 32.3994 23.7271 33.4737 24.8539C34.5478 25.9804 35.15 27.5069 35.15 29.0971V29.9824C35.15 30.5501 34.935 31.0959 34.5503 31.4994C34.1654 31.9032 33.6417 32.1314 33.0941 32.1314H25.9946C25.9541 32.6396 25.7416 33.1189 25.3915 33.4809C25.0074 33.878 24.4881 34.102 23.9455 34.102C23.9455 34.102 23.9455 34.102 23.9455 34.102H14.9059C14.3583 34.102 13.8346 33.8737 13.4497 33.47C13.065 33.0664 12.85 32.5206 12.85 31.9529V31.0961C12.85 29.5059 13.4522 27.9795 14.5263 26.853C15.0582 26.2951 15.69 25.8521 16.3859 25.5498C17.0818 25.2475 17.828 25.0917 18.5817 25.0917H20.2968H20.297V25.2417C21.2505 25.2388 22.1885 25.4946 23.0198 25.9843L27.7301 23.0927ZM27.7301 23.0927C26.7609 23.093 25.8081 23.352 24.9615 23.8448C24.1565 24.3133 23.4729 24.9783 22.9678 25.7818L27.7301 23.0927ZM27.7295 24.535H27.7302H29.4451C30.5897 24.5424 31.6866 25.0244 32.4958 25.8784C33.3054 26.7327 33.7609 27.8897 33.7609 29.0971V29.9538C33.7609 30.1435 33.689 30.3241 33.5631 30.4561C33.4374 30.5879 33.2685 30.6606 33.0941 30.6606H25.9932C25.9405 29.8919 25.7463 29.1405 25.4207 28.448C25.0929 27.7508 24.6384 27.1273 24.0827 26.6124C24.4651 25.9892 24.9872 25.4739 25.6033 25.1119C26.2531 24.73 26.9856 24.5315 27.7295 24.535ZM18.5817 26.5341H20.2966C21.4412 26.5415 22.5381 27.0235 23.3473 27.8774C24.1569 28.7317 24.6124 29.8887 24.6124 31.0961V31.9529C24.6124 32.1426 24.5405 32.3231 24.4146 32.4552C24.2889 32.587 24.12 32.6596 23.9455 32.6596H14.9059C14.7315 32.6596 14.5626 32.587 14.4369 32.4552C14.311 32.3231 14.2391 32.1426 14.2391 31.9529V31.0961C14.2391 29.884 14.6983 28.7228 15.5135 27.8677C16.3285 27.0129 17.4323 26.5341 18.5817 26.5341Z" fill="#38BDF8" stroke="#2563EB" strokeWidth="0.3" />
    <path d="M16.7206 20.2514H16.7441L16.7441 20.1014C16.7441 19.5353 16.9046 18.9825 17.2043 18.5133C17.504 18.0441 17.9291 17.68 18.4248 17.4661C18.9204 17.2523 19.465 17.1978 19.9897 17.3094C20.5145 17.4209 20.9969 17.6937 21.3752 18.0943C21.7536 18.495 22.0107 19.0055 22.1128 19.5612C22.2149 20.1169 22.1572 20.6919 21.9473 21.2128C21.7374 21.7336 21.3852 22.1761 20.9364 22.485C20.4878 22.7938 19.9625 22.9553 19.4273 22.95L19.4273 22.95H19.4258C18.7097 22.95 18.0214 22.6517 17.5128 22.1182C17.0376 21.6198 16.7559 20.9543 16.7206 20.2514ZM23.5238 20.1014C23.5238 19.2543 23.2844 18.4256 22.835 17.7202C22.3855 17.0147 21.746 16.4637 20.9964 16.1381C20.2467 15.8124 19.4213 15.727 18.6249 15.8932C17.8286 16.0593 17.0982 16.4692 16.5256 17.0698C15.953 17.6703 15.5639 18.4346 15.4063 19.2656C15.2487 20.0966 15.3295 20.958 15.6388 21.7411C15.9481 22.5244 16.4724 23.1949 17.1463 23.6673C17.8204 24.1397 18.6137 24.3923 19.4258 24.3923C20.515 24.3923 21.5581 23.9384 22.326 23.133C23.0937 22.3278 23.5238 21.2371 23.5238 20.1014Z" fill="#38BDF8" stroke="#2563EB" strokeWidth="0.3" />
    <path d="M25.869 18.2521H25.8924L25.8924 18.1021C25.8925 17.536 26.0529 16.9832 26.3526 16.514C26.6523 16.0448 27.0774 15.6807 27.5731 15.4669C28.0687 15.253 28.6133 15.1986 29.138 15.3101C29.6628 15.4217 30.1452 15.6945 30.5235 16.0951C30.9019 16.4958 31.159 17.0062 31.2611 17.5619C31.3632 18.1177 31.3055 18.6926 31.0956 19.2135C30.8857 19.7343 30.5335 20.1769 30.0848 20.4857C29.6361 20.7945 29.1108 20.9561 28.5756 20.9507L28.5756 20.9507H28.5741C27.858 20.9507 27.1698 20.6524 26.6611 20.1189C26.1859 19.6205 25.9042 18.955 25.869 18.2521ZM32.6721 18.1021C32.6721 17.255 32.4327 16.4264 31.9833 15.7209C31.5339 15.0155 30.8943 14.4645 30.1447 14.1388C29.395 13.8131 28.5696 13.7278 27.7733 13.8939C26.9769 14.06 26.2465 14.4699 25.6739 15.0705C25.1013 15.671 24.7122 16.4353 24.5546 17.2663C24.397 18.0973 24.4778 18.9587 24.7871 19.7419C25.0964 20.5251 25.6207 21.1957 26.2946 21.668C26.9687 22.1404 27.762 22.393 28.5741 22.393C29.6634 22.393 30.7064 21.9391 31.4744 21.1337C32.242 20.3285 32.6721 19.2379 32.6721 18.1021Z" fill="#38BDF8" stroke="#2563EB" strokeWidth="0.3" />
  </svg>
);

export const TrialAgencies = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#F8FAFC" />
    <path d="M24.8642 28.8928C24.8642 26.6679 26.6679 24.8642 28.8928 24.8642C31.1177 24.8642 32.9214 26.6679 32.9214 28.8928C32.9214 31.1177 31.1177 32.9214 28.8928 32.9214C26.6679 32.9214 24.8642 31.1177 24.8642 28.8928ZM28.8928 23.6356C25.9893 23.6356 23.6356 25.9893 23.6356 28.8928C23.6356 31.7963 25.9893 34.1499 28.8928 34.1499C31.7963 34.1499 34.1499 31.7963 34.1499 28.8928C34.1499 25.9893 31.7963 23.6356 28.8928 23.6356Z" fill="#ED4F9D" stroke="#ED4F9D" strokeWidth="0.3" />
    <path d="M18.8286 19.1429C18.8286 17.1744 20.4244 15.5786 22.3929 15.5786C24.3613 15.5786 25.9571 17.1744 25.9571 19.1429C25.9571 21.1114 24.3613 22.7071 22.3929 22.7071C20.4244 22.7071 18.8286 21.1114 18.8286 19.1429ZM22.3929 14.35C19.7458 14.35 17.6 16.4958 17.6 19.1429C17.6 21.7899 19.7458 23.9357 22.3929 23.9357C25.0399 23.9357 27.1857 21.7899 27.1857 19.1429C27.1857 16.4958 25.0399 14.35 22.3929 14.35Z" fill="#ED4F9D" stroke="#ED4F9D" strokeWidth="0.3" />
    <path d="M22.3929 23.9357H22.5429V23.7857V22.8572V22.7072H22.3929C17.9509 22.7072 14.35 26.3081 14.35 30.75V30.9H14.5H15.4286H15.5786V30.75C15.5786 26.9866 18.6294 23.9357 22.3929 23.9357Z" fill="#ED4F9D" stroke="#ED4F9D" strokeWidth="0.3" />
    <path d="M28.4285 26.4215H28.2785V26.5715V28.743H27.0356H26.8856V28.893V29.8215V29.9715H27.0356H28.8928C29.232 29.9715 29.5071 29.6965 29.5071 29.3572V26.5715V26.4215H29.3571H28.4285Z" fill="#ED4F9D" stroke="#ED4F9D" strokeWidth="0.3" />
  </svg>
);

export const PaidAgencies = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#EFF6FF" />
    <path d="M19.9714 20.3571C19.9714 18.073 21.823 16.2214 24.1071 16.2214C26.3912 16.2214 28.2429 18.0731 28.2429 20.3571C28.2429 22.6412 26.3912 24.4929 24.1071 24.4929C21.823 24.4929 19.9714 22.6412 19.9714 20.3571ZM24.1071 14.85C21.0656 14.85 18.6 17.3156 18.6 20.3571C18.6 23.3987 21.0656 25.8643 24.1071 25.8643C27.1486 25.8643 29.6143 23.3987 29.6143 20.3571C29.6143 17.3156 27.1486 14.85 24.1071 14.85Z" fill="#2563EB" stroke="#2563EB" strokeWidth="0.3" />
    <path d="M24.1071 25.8643H24.2571V25.7143V24.6428V24.4928H24.1071C18.9946 24.4928 14.85 28.6374 14.85 33.75V33.9H15H16.0714H16.2214V33.75C16.2214 29.3948 19.752 25.8643 24.1071 25.8643Z" fill="#2563EB" stroke="#2563EB" strokeWidth="0.3" />
    <path d="M30 25C27.2425 25 25 27.2429 25 30C25 32.7571 27.2425 35 30 35C32.7568 35 35 32.7571 35 30C35 27.2429 32.7568 25 30 25ZM29.6211 32.915V32.2364C29.1425 32.215 28.6779 32.0864 28.4068 31.9293L28.6211 31.0936C28.9211 31.2579 29.3425 31.4079 29.8068 31.4079C30.2143 31.4079 30.4925 31.2507 30.4925 30.965C30.4925 30.6936 30.2643 30.5221 29.7357 30.3436C28.9711 30.0861 28.4493 29.7289 28.4493 29.0361C28.4493 28.4071 28.8921 27.9143 29.6568 27.7643V27.0857H30.3571V27.7143C30.8357 27.7357 31.1571 27.8357 31.3929 27.95L31.1857 28.7571C30.9996 28.6786 30.6711 28.5143 30.1568 28.5143C29.6921 28.5143 29.5421 28.7143 29.5421 28.9143C29.5421 29.15 29.7925 29.3 30.3993 29.5289C31.2496 29.8289 31.5929 30.2218 31.5929 30.8646C31.5929 31.5004 31.1421 32.0436 30.3204 32.1864V32.915H29.6211Z" fill="#2563EB" />
  </svg>
);

export const TotalAgencies = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#FFFBEB" />
    <path d="M23.5898 24C26.3513 24 28.5898 21.7614 28.5898 19C28.5898 16.2386 26.3513 14 23.5898 14C20.8284 14 18.5898 16.2386 18.5898 19C18.5898 21.7614 20.8284 24 23.5898 24Z" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M32.18 34C32.18 30.13 28.33 27 23.59 27C18.85 27 15 30.13 15 34" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const MarketingIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#F8FAFC" />
    <path d="M24 33C28.9706 33 33 28.9706 33 24C33 19.0294 28.9706 15 24 15C19.0294 15 15 19.0294 15 24C15 28.9706 19.0294 33 24 33Z" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M26.8 21.0002C26.6188 20.6859 26.3557 20.4269 26.0386 20.2507C25.7215 20.0746 25.3625 19.988 25 20.0002H23C22.4696 20.0002 21.9609 20.2109 21.5858 20.5859C21.2107 20.961 21 21.4697 21 22.0002C21 22.5306 21.2107 23.0393 21.5858 23.4144C21.9609 23.7894 22.4696 24.0002 23 24.0002H25C25.5304 24.0002 26.0391 24.2109 26.4142 24.5859C26.7893 24.961 27 25.4697 27 26.0002C27 26.5306 26.7893 27.0393 26.4142 27.4144C26.0391 27.7894 25.5304 28.0002 25 28.0002H23C22.6375 28.0124 22.2785 27.9258 21.9614 27.7496C21.6443 27.5735 21.3812 27.3144 21.2 27.0002" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24 28V30M24 18V20V18Z" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const ReturnIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#FDF2F8" />
    <path d="M24 20V24L26 26" stroke="#ED4F9D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.05 23.0001C15.2741 20.8001 16.3003 18.7597 17.9328 17.268C19.5653 15.7764 21.6897 14.938 23.9009 14.9127C26.1121 14.8874 28.2552 15.6771 29.9213 17.1311C31.5874 18.5851 32.6599 20.6015 32.9342 22.7958C33.2085 24.99 32.6654 27.2085 31.4084 29.0278C30.1515 30.8472 28.2687 32.1401 26.1193 32.6599C23.97 33.1797 21.7045 32.89 19.755 31.8461C17.8056 30.8022 15.05 27.0001 15.05 27.0001M15.05 32.0001V27.0001M15.05 27.0001H20.05" stroke="#ED4F9D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const TotalRevenuIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#F8FAFC" />
    <path d="M24 33C28.9706 33 33 28.9706 33 24C33 19.0294 28.9706 15 24 15C19.0294 15 15 19.0294 15 24C15 28.9706 19.0294 33 24 33Z" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M26.8 21.0002C26.6188 20.6859 26.3557 20.4269 26.0386 20.2507C25.7215 20.0746 25.3625 19.988 25 20.0002H23C22.4696 20.0002 21.9609 20.2109 21.5858 20.5859C21.2107 20.961 21 21.4697 21 22.0002C21 22.5306 21.2107 23.0393 21.5858 23.4144C21.9609 23.7894 22.4696 24.0002 23 24.0002H25C25.5304 24.0002 26.0391 24.2109 26.4142 24.5859C26.7893 24.961 27 25.4697 27 26.0002C27 26.5306 26.7893 27.0393 26.4142 27.4144C26.0391 27.7894 25.5304 28.0002 25 28.0002H23C22.6375 28.0124 22.2785 27.9258 21.9614 27.7496C21.6443 27.5735 21.3812 27.3144 21.2 27.0002" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24 28V30M24 18V20V18Z" stroke="#38BDF8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const SalesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="12" fill="#FFFBEB" />
    <path d="M18 33C19.1046 33 20 32.1046 20 31C20 29.8954 19.1046 29 18 29C16.8954 29 16 29.8954 16 31C16 32.1046 16.8954 33 18 33Z" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M29 33C30.1046 33 31 32.1046 31 31C31 29.8954 30.1046 29 29 29C27.8954 29 27 29.8954 27 31C27 32.1046 27.8954 33 29 33Z" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M29 29H18V15H16" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M32 18L31 25H18" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 22L28 16" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.5 17C22.7761 17 23 16.7761 23 16.5C23 16.2239 22.7761 16 22.5 16C22.2239 16 22 16.2239 22 16.5C22 16.7761 22.2239 17 22.5 17Z" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27.5 22C27.7761 22 28 21.7761 28 21.5C28 21.2239 27.7761 21 27.5 21C27.2239 21 27 21.2239 27 21.5C27 21.7761 27.2239 22 27.5 22Z" stroke="#F6A723" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const AgenciICon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M10.5 6.125V2.625H1.75V18.375H19.25V6.125H10.5ZM5.25 16.625H3.5V14.875H5.25V16.625ZM5.25 13.125H3.5V11.375H5.25V13.125ZM5.25 9.625H3.5V7.875H5.25V9.625ZM5.25 6.125H3.5V4.375H5.25V6.125ZM8.75 16.625H7V14.875H8.75V16.625ZM8.75 13.125H7V11.375H8.75V13.125ZM8.75 9.625H7V7.875H8.75V9.625ZM8.75 6.125H7V4.375H8.75V6.125ZM17.5 16.625H10.5V14.875H12.25V13.125H10.5V11.375H12.25V9.625H10.5V7.875H17.5V16.625ZM15.75 9.625H14V11.375H15.75V9.625ZM15.75 13.125H14V14.875H15.75V13.125Z" fill="#84818A" />
  </svg>
);

export const CheckFilled = (props) => (
  <svg className="CheckFilled" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20" fill="none">
    <rect width="20" height="20" rx="10" fill="#0E8E2A" />
    <path d="M6.25 10L9.375 13.125L15 7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const ProDimond = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 16 11" fill="none">
    <path d="M7.61048 10.6695L9.58138 3.71092H5.64006L7.61048 10.6695ZM1.34401 2.99283C1.54226 2.99283 1.70306 3.15363 1.70306 3.35187C1.70306 3.55012 1.54226 3.71092 1.34401 3.71092H0.359044C0.160802 3.71092 0 3.55012 0 3.35187C0 3.15363 0.160802 2.99283 0.359044 2.99283H1.34401ZM13.0086 5.86806C12.868 5.7279 12.868 5.50038 13.0086 5.36021C13.1488 5.22005 13.3763 5.22005 13.5165 5.36021L14.247 6.09078C14.3872 6.23142 14.3872 6.45846 14.247 6.59863C14.1069 6.73879 13.8793 6.73879 13.7392 6.59863L13.0086 5.86806ZM13.7392 0.105121C13.8798 -0.0350404 14.1069 -0.0350404 14.247 0.105121C14.3872 0.245762 14.3872 0.472805 14.247 0.612966L13.5165 1.34353C13.3763 1.48369 13.1488 1.48369 13.0086 1.34353C12.868 1.20337 12.868 0.97585 13.0086 0.835688L13.7392 0.105121ZM13.8769 3.7114C13.6787 3.7114 13.5179 3.5506 13.5179 3.35235C13.5179 3.15411 13.6787 2.99331 13.8769 2.99331H14.8619C15.0602 2.99331 15.221 3.15411 15.221 3.35235C15.221 3.5506 15.0602 3.7114 14.8619 3.7114H13.8769ZM1.7045 5.36021C1.84466 5.22005 2.07218 5.22005 2.21234 5.36021C2.35298 5.50086 2.35298 5.7279 2.21234 5.86806L1.48177 6.59863C1.34113 6.73879 1.11409 6.73879 0.97393 6.59863C0.833768 6.45846 0.833768 6.23094 0.97393 6.09078L1.7045 5.36021ZM0.97393 0.612966C0.833768 0.472805 0.833768 0.245282 0.97393 0.105121C1.11409 -0.0350404 1.34161 -0.0350404 1.48177 0.105121L2.21234 0.835688C2.35298 0.97585 2.35298 1.20337 2.21234 1.34353C2.07218 1.48369 1.84466 1.48369 1.7045 1.34353L0.97393 0.612966ZM9.61258 2.99283L9.23049 1.03729H5.98998L5.6079 2.99283H9.61258ZM8.83737 8.97369L12.6438 3.7114H10.3278L8.83737 8.97369ZM12.7244 2.99283L11.7491 1.03729H9.9625L10.3446 2.99283H12.7244ZM5.25845 1.03729H3.47187L2.49651 2.99283H4.87637L5.25845 1.03729ZM2.57763 3.7114L6.38406 8.97369L4.89365 3.7114H2.57763Z" fill="url(#paint0_diamond_1508_89)" />
    <defs>
      <radialGradient id="paint0_diamond_1508_89" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.61048 5.33477) rotate(90) scale(5.33477 7.61048)">
        <stop stopColor="#19A9FC" />
        <stop offset="1" stopColor="#2563EB" />
      </radialGradient>
    </defs>
  </svg>
);

export const AlertIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z" stroke="#FF3B3B" strokeOpacity="0.37" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 6V9.75" stroke="#FF3B3B" strokeOpacity="0.37" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.99609 12H9.00283" stroke="#FF3B3B" strokeOpacity="0.37" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const FilledEmailIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
    <path d="M15 0H1.66666C0.75 0 0.00832999 0.75 0.00832999 1.66666L0 11.6667C0 12.5833 0.75 13.3333 1.66666 13.3333H15C15.9167 13.3333 16.6667 12.5833 16.6667 11.6667V1.66666C16.6667 0.75 15.9167 0 15 0ZM15 3.33333L8.33335 7.5L1.66666 3.33333V1.66666L8.33335 5.83333L15 1.66666V3.33333Z" fill="#2E2C34" />
  </svg>
);

export const PhoneIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
    <path d="M10.1669 0.841341L1.83365 0.833008C0.916992 0.833008 0.166992 1.58301 0.166992 2.49967V17.4997C0.166992 18.4163 0.916992 19.1663 1.83365 19.1663H10.1669C11.0836 19.1663 11.8336 18.4163 11.8336 17.4997V2.49967C11.8336 1.58301 11.0836 0.841341 10.1669 0.841341ZM10.1669 15.833H1.83365V4.16634H10.1669V15.833Z" fill="#2E2C34" />
  </svg>
);
export const PinIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.99984 1.6665C6.77484 1.6665 4.1665 4.27484 4.1665 7.49984C4.1665 11.8748 9.99984 18.3332 9.99984 18.3332C9.99984 18.3332 15.8332 11.8748 15.8332 7.49984C15.8332 4.27484 13.2248 1.6665 9.99984 1.6665ZM9.99984 9.58317C8.84984 9.58317 7.9165 8.64984 7.9165 7.49984C7.9165 6.34984 8.84984 5.4165 9.99984 5.4165C11.1498 5.4165 12.0832 6.34984 12.0832 7.49984C12.0832 8.64984 11.1498 9.58317 9.99984 9.58317Z" fill="#84818A" />
  </svg>
);

export const InfiniteIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" fill="none">
    <path d="M1.11564 7.83213C1.35284 9.62093 2.21094 10.1916 2.61044 10.2534C2.47454 10.0558 2.18294 9.58383 2.10394 9.27753C2.00504 8.89453 1.94334 7.83213 2.61044 6.78213C3.27744 5.73203 4.47574 5.12672 5.62464 4.76852C6.54374 4.48192 7.41594 4.53373 7.73714 4.59553C8.03354 4.58323 7.77684 4.30712 6.69944 3.91612C5.51344 3.48372 4.21634 3.37253 3.14164 3.97783C2.06684 4.58323 0.819143 5.59613 1.11564 7.83213Z" fill="url(#paint0_linear_1512_132)" />
    <path d="M1.11566 7.83285C1.35286 9.62165 2.21096 10.1924 2.61046 10.2542C2.47456 10.0566 2.18296 9.58455 2.10396 9.27825C2.00506 8.89525 1.94336 7.83285 2.61046 6.78285C2.90596 6.31765 3.30576 5.93975 3.75926 5.63195C3.95696 5.52275 4.03106 5.44865 4.03106 5.44865C3.72226 5.38685 3.24046 5.31275 2.61046 5.44865C1.98036 5.58455 1.37506 5.94275 1.13296 6.48635C1.06066 6.87825 1.04816 7.32385 1.11566 7.83285Z" fill="url(#paint1_linear_1512_132)" />
    <path d="M2.54737 5.34906C1.95447 6.11496 1.36947 7.39676 2.11067 9.27446C1.95007 8.73086 2.17867 7.70246 2.38867 7.18356C2.59867 6.66476 3.40656 5.70176 4.75266 5.06496C5.90156 4.52136 7.27467 4.52136 7.92117 4.57076C7.58767 4.41436 6.64387 4.09886 5.53697 4.08906C4.15337 4.07666 3.22687 4.66966 2.54737 5.34906Z" fill="url(#paint2_linear_1512_132)" />
    <path d="M19.081 1.08853C21.4279 1.51963 23.1576 2.64893 24.5302 5.45603C24.1789 5.14803 23.4151 4.41253 22.8339 4.04663C22.1075 3.58923 20.7603 3.06833 20.26 2.53833C19.3559 1.70323 17.9235 1.24183 17.3149 1.17763C16.7063 1.11333 17.0249 0.77883 19.081 1.08853Z" fill="url(#paint3_linear_1512_132)" />
    <path d="M2.93655 11.9721C1.20415 11.0634 0.74895 8.95067 0.86985 7.58487C0.99065 6.21917 1.34495 5.99937 1.27455 6.12027C1.04495 7.72767 1.34115 9.53077 2.63435 10.0021C3.92755 10.4735 5.18455 9.42197 6.28435 8.35847C7.38415 7.29487 9.51125 5.40947 10.3332 4.58757C11.155 3.76577 14.3053 1.11657 16.6984 0.983567C18.9222 0.860067 20.1428 2.03507 21.0655 3.18567C21.6201 3.87727 22.0564 5.56657 21.8389 5.36107C21.6213 5.15567 21.0291 4.59967 19.9172 4.29757C18.8053 3.99537 17.3551 4.14047 16.0981 4.52717C14.8412 4.91397 13.2096 6.03797 12.1823 6.98067C11.155 7.92337 8.66525 10.4251 7.51705 11.2832C6.36895 12.1413 4.60395 12.8468 2.93655 11.9721Z" fill="url(#paint4_linear_1512_132)" />
    <path d="M7.52875 11.3071C6.38055 12.1652 4.59185 12.9145 2.94815 11.996C1.75965 11.3071 1.44545 10.507 1.62675 10.7125C1.80805 10.9179 3.10125 12.6583 5.22835 11.5101C7.35545 10.362 9.55515 7.37679 11.3075 5.73309C13.06 4.08939 14.2646 3.12249 16.1097 2.57869C17.9548 2.03479 20.3035 2.39009 21.077 3.20959C21.6316 3.90119 22.0681 5.59049 21.8505 5.38499C21.633 5.17959 21.0408 4.62359 19.9289 4.32149C18.817 4.01929 17.3666 4.16429 16.1097 4.55109C14.8528 4.93779 13.2212 6.06179 12.1939 7.00449C11.1666 7.94719 8.67695 10.449 7.52875 11.3071Z" fill="url(#paint5_linear_1512_132)" />
    <path d="M8.1257 11.8651C6.9524 12.6574 5.1246 13.3492 3.4449 12.5012C2.2304 11.8651 1.9093 11.1265 2.0946 11.3162C2.2798 11.5058 3.6073 13.0252 5.775 12.0526C7.9814 11.0626 10.1964 8.2365 11.9872 6.719C13.778 5.2015 15.009 4.3088 16.8945 3.8067C18.7799 3.3046 21.18 3.6327 21.9704 4.3892C22.5372 5.0277 22.9832 6.5873 22.7609 6.3977C22.5386 6.208 21.9334 5.6947 20.7972 5.4157C19.6609 5.1368 18.1789 5.2707 16.8945 5.6277C15.61 5.9848 13.9427 7.0225 12.8929 7.8928C11.8432 8.7632 9.299 11.0729 8.1257 11.8651Z" fill="url(#paint6_linear_1512_132)" />
    <path d="M20.8565 3.00265C20.6873 2.82135 22.1153 3.37625 23.4931 4.45185C23.9124 4.77925 24.1076 4.90015 24.3856 5.21435C24.6454 5.50805 25.173 7.42505 25.173 8.33145C25.1247 10.1685 24.6408 11.3153 24.5324 11.5473C24.3856 11.8617 24.3372 11.9945 24.3856 11.0638C24.4339 10.1332 24.3131 8.87635 23.7934 7.78865C23.1286 6.70085 22.7177 6.41085 22.0046 5.66155C21.8475 5.49235 21.908 5.17805 21.775 4.77925C21.63 4.30785 21.0257 3.18385 20.8565 3.00265Z" fill="url(#paint7_linear_1512_132)" />
    <path d="M4.04404 5.45635C3.85064 5.39835 4.34624 5.06945 5.74814 4.63435C6.31624 4.51345 7.52484 4.17525 8.06864 4.56185C8.83004 4.92015 10.5704 6.56805 11.3077 7.31745C11.9281 7.90965 13.4493 9.25355 14.5709 9.89175C15.9728 10.6893 18.0637 11.62 19.659 11.1003C21.2544 10.5806 21.8828 8.57435 21.907 7.72835C21.9312 6.88235 21.907 5.79455 21.907 5.51665C21.903 5.44405 21.9819 5.40055 22.33 5.80665C22.7651 6.31425 23.2848 6.56805 23.9495 8.11505C24.2033 8.75165 24.6257 10.5201 24.4571 11.4991C24.3115 12.3451 23.9611 12.7923 23.2969 13.5416C22.6327 14.2909 21.1577 15.3666 19.3811 15.3787C17.6044 15.3908 16.2145 14.6414 15.4531 14.2789C14.172 13.6866 12.0812 12.1034 10.9572 10.9794C9.82114 9.94005 8.67294 8.77985 7.95994 8.06675C7.38944 7.49625 6.53774 6.66885 6.18324 6.32635C5.47024 5.77045 4.32204 5.53975 4.04404 5.45635Z" fill="url(#paint8_linear_1512_132)" />
    <path d="M8.08124 4.55373C7.58814 4.32173 6.32884 4.50543 5.76074 4.62623C5.98054 4.70693 6.70294 5.11243 7.83414 6.08883C9.24824 7.30933 11.2786 9.19503 13.1036 10.343C15.2911 11.6846 17.3807 12.3008 19.6716 11.0922C18.0763 11.6119 15.9854 10.6813 14.5835 9.88363C13.4619 9.24553 11.9407 7.90153 11.3203 7.30933C10.583 6.56003 8.84274 4.91203 8.08124 4.55373Z" fill="url(#paint9_linear_1512_132)" />
    <path d="M22.2942 13.5139C21.2983 14.4517 19.3654 14.4848 18.5234 14.384L23.6962 10.4199C23.6962 10.4199 23.5391 12.3415 22.2942 13.5139Z" fill="url(#paint10_linear_1512_132)" />
    <defs>
      <linearGradient id="paint0_linear_1512_132" x1="6.84034" y1="6.52273" x2="4.45864" y2="3.55763" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8D2EB0" />
        <stop offset="0.932292" stopColor="#6E11B8" />
      </linearGradient>
      <linearGradient id="paint1_linear_1512_132" x1="0.774757" y1="10.4519" x2="2.91196" y2="4.89275" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2563EB" />
        <stop offset="1" stopColor="#BA6CD9" />
      </linearGradient>
      <linearGradient id="paint2_linear_1512_132" x1="0.441265" y1="6.85616" x2="5.51857" y2="5.20086" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D9D9D9" stop-opacity="0.59" />
        <stop offset="1" stopColor="#D9D9D9" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint3_linear_1512_132" x1="21.5633" y1="4.50193" x2="19.3474" y2="-0.0391699" gradientUnits="userSpaceOnUse">
        <stop stopColor="#615EFF" />
        <stop offset="1" stopColor="#FF5EB0" />
      </linearGradient>
      <linearGradient id="paint4_linear_1512_132" x1="25.1142" y1="8.21337" x2="0.857751" y2="7.64537" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8360FD" />
        <stop offset="1" stopColor="#A037D9" />
      </linearGradient>
      <linearGradient id="paint5_linear_1512_132" x1="18.8982" y1="5.98689" x2="4.09295" y2="7.06249" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CFB9FE" />
        <stop offset="1" stopColor="#0029FF" />
      </linearGradient>
      <linearGradient id="paint6_linear_1512_132" x1="19.7439" y1="6.9533" x2="4.6326" y2="8.1685" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A69EFF" />
        <stop offset="1" stopColor="#2B91FE" />
      </linearGradient>
      <linearGradient id="paint7_linear_1512_132" x1="25.3905" y1="11.6067" x2="20.254" y2="1.30955" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2C92FF" />
        <stop offset="1" stopColor="#0001C5" />
      </linearGradient>
      <linearGradient id="paint8_linear_1512_132" x1="26.5601" y1="16.4906" x2="16.3354" y2="-0.0791513" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2563EB" />
        <stop offset="0.308321" stopColor="#4C60FF" />
        <stop offset="0.802083" stopColor="#925FFF" />
      </linearGradient>
      <linearGradient id="paint9_linear_1512_132" x1="16.8985" y1="13.5821" x2="11.4357" y2="4.63853" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint10_linear_1512_132" x1="23.0919" y1="15.2301" x2="20.2638" y2="11.8823" gradientUnits="userSpaceOnUse">
        <stop offset="0.295908" stopColor="#D9D9D9" />
        <stop offset="0.667869" stopColor="#D9D9D9" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const ShareCircleIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.6875 3.125C8.6875 1.77881 9.77881 0.6875 11.125 0.6875C12.4712 0.6875 13.5625 1.77881 13.5625 3.125C13.5625 4.47119 12.4712 5.5625 11.125 5.5625C10.4053 5.5625 9.75842 5.25057 9.31223 4.75453L7.27425 5.86615L4.99386 7.16923C5.19664 7.5248 5.3125 7.93637 5.3125 8.375C5.3125 8.59726 5.28275 8.81258 5.22702 9.01718L9.31223 11.2455C9.75842 10.7494 10.4053 10.4375 11.125 10.4375C12.4712 10.4375 13.5625 11.5288 13.5625 12.875C13.5625 14.2212 12.4712 15.3125 11.125 15.3125C9.77881 15.3125 8.6875 14.2212 8.6875 12.875C8.6875 12.6527 8.71725 12.4374 8.77298 12.2328L4.68778 10.0045C4.24159 10.5006 3.59472 10.8125 2.875 10.8125C1.52881 10.8125 0.4375 9.72119 0.4375 8.375C0.4375 7.02881 1.52881 5.9375 2.875 5.9375C3.36301 5.9375 3.81752 6.08091 4.19869 6.32789L6.72576 4.88385L8.77298 3.76719C8.71725 3.56258 8.6875 3.34727 8.6875 3.125ZM11.125 1.8125C10.4001 1.8125 9.8125 2.40013 9.8125 3.125C9.8125 3.84987 10.4001 4.4375 11.125 4.4375C11.8499 4.4375 12.4375 3.84987 12.4375 3.125C12.4375 2.40013 11.8499 1.8125 11.125 1.8125ZM2.875 7.0625C2.15013 7.0625 1.5625 7.65013 1.5625 8.375C1.5625 9.09987 2.15013 9.6875 2.875 9.6875C3.59987 9.6875 4.1875 9.09987 4.1875 8.375C4.1875 7.65013 3.59987 7.0625 2.875 7.0625ZM9.8125 12.875C9.8125 12.1501 10.4001 11.5625 11.125 11.5625C11.8499 11.5625 12.4375 12.1501 12.4375 12.875C12.4375 13.5999 11.8499 14.1875 11.125 14.1875C10.4001 14.1875 9.8125 13.5999 9.8125 12.875Z" fill="#3563EF" />
  </svg>
);

export const DownloadIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M7.5 9.1665V14.1665L9.16667 12.4998" stroke="#9DA9BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.49967 14.1667L5.83301 12.5" stroke="#9DA9BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.3337 8.33317V12.4998C18.3337 16.6665 16.667 18.3332 12.5003 18.3332H7.50033C3.33366 18.3332 1.66699 16.6665 1.66699 12.4998V7.49984C1.66699 3.33317 3.33366 1.6665 7.50033 1.6665H11.667" stroke="#9DA9BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.3337 8.33317H15.0003C12.5003 8.33317 11.667 7.49984 11.667 4.99984V1.6665L18.3337 8.33317Z" stroke="#9DA9BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const FilterRefresh = (props) => (
  <svg className="FilterRefresh" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <circle cx="7" cy="7" r="6.5" stroke="black" stroke-opacity="0.34" />
    <path d="M4.32327 4.50505C4.21765 4.47666 4.12987 4.40193 4.08367 4.30107C4.03746 4.20022 4.0376 4.08366 4.08405 3.98292L4.89903 2.21554C4.95976 2.08384 5.08978 1.99982 5.23259 2C5.37539 2.00018 5.50521 2.08453 5.56562 2.21638L5.92514 3.00108C5.938 2.99594 5.95127 2.99147 5.96492 2.98774C8.09828 2.40525 10.2911 3.69534 10.8627 5.86924C11.4344 8.04314 10.1683 10.2776 8.03499 10.8601C5.90163 11.4426 3.7088 10.1525 3.13717 7.97864C2.95539 7.28735 2.95948 6.58883 3.11869 5.9394C3.16795 5.73845 3.36775 5.61624 3.56496 5.66644C3.76216 5.71663 3.88209 5.92023 3.83283 6.12119C3.70313 6.65024 3.69956 7.21933 3.84817 7.78451C4.31459 9.55826 6.10379 10.6109 7.84447 10.1356C9.58515 9.66034 10.6181 7.83713 10.1517 6.06337C9.69295 4.31862 7.95427 3.2716 6.24085 3.69017L6.60359 4.4819C6.66401 4.61376 6.64411 4.76933 6.55255 4.88101C6.46099 4.99269 6.3143 5.04031 6.17622 5.00319L4.32327 4.50505Z" fill="#64748B" />
  </svg>
);

export const UnCheckedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#F5F5F5" stroke="#CDCDCD" />
  </svg>
);

export const CheckedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect width="16" height="16" rx="4" fill="#2563EB" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0199 5.31344C12.2151 5.5087 12.2151 5.82529 12.0199 6.02055L7.35323 10.6872C7.15796 10.8825 6.84138 10.8825 6.64612 10.6872L3.97945 8.02055C3.78419 7.82529 3.78419 7.5087 3.97945 7.31344C4.17471 7.11818 4.4913 7.11818 4.68656 7.31344L6.99967 9.62655L11.3128 5.31344C11.508 5.11818 11.8246 5.11818 12.0199 5.31344Z" fill="white" />
  </svg>
);

export const CreatePaymentIcon = (props) => (
  <svg className="CreatePaymentIcon" xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
    <path d="M5 6.83301H8.66667" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.83301 8.66667V5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.15999 12.8C3.70666 12.2133 4.54 12.26 5.02 12.9L5.69333 13.8C6.23333 14.5133 7.10667 14.5133 7.64667 13.8L8.32 12.9C8.8 12.26 9.63333 12.2133 10.18 12.8C11.3667 14.0667 12.3333 13.6467 12.3333 11.8733V4.36C12.3333 1.67333 11.7067 1 9.18667 1H4.14667C1.62667 1 1 1.67333 1 4.36V11.8667C1.00667 13.6467 1.97999 14.06 3.15999 12.8Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CloseIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M10.9492 1.05029L1.04972 10.9498" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.0498 1.05029L10.9493 10.9498" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const UsersIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
    <path d="M12.0006 11.944V10.8885C12.0006 10.3286 11.7296 9.79164 11.247 9.39574C10.7645 8.99984 10.1101 8.77734 9.42771 8.77734H4.2819C3.59952 8.77734 2.94509 8.99984 2.46257 9.39574C1.98006 9.79164 1.70898 10.3286 1.70898 10.8885V11.944" stroke="#9AA6B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.45898 5.61133C7.77066 5.61133 8.83398 4.54801 8.83398 3.23633C8.83398 1.92465 7.77066 0.861328 6.45898 0.861328C5.14731 0.861328 4.08398 1.92465 4.08398 3.23633C4.08398 4.54801 5.14731 5.61133 6.45898 5.61133Z" stroke="#9AA6B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.1683 11.944V10.8651C15.168 10.387 15.0123 9.92254 14.7257 9.54464C14.4391 9.16684 14.0379 8.89694 13.585 8.77734" fill="#F1F1F1" />
    <path d="M15.1683 11.944V10.8651C15.168 10.387 15.0123 9.92254 14.7257 9.54464C14.4391 9.16684 14.0379 8.89694 13.585 8.77734" stroke="#9AA6B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.21 0.861328C11.6629 0.996348 12.0643 1.30305 12.351 1.73307C12.6377 2.16309 12.7933 2.69197 12.7933 3.23633C12.7933 3.78069 12.6377 4.30957 12.351 4.73959C12.0643 5.16961 11.6629 5.47631 11.21 5.61133" stroke="#9AA6B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const UserIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path d="M5.75 5.75C7.06168 5.75 8.125 4.68668 8.125 3.375C8.125 2.06332 7.06168 1 5.75 1C4.43832 1 3.375 2.06332 3.375 3.375C3.375 4.68668 4.43832 5.75 5.75 5.75Z" stroke="#9AA6B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.2917 12.0837V11.0281C11.2917 10.4682 11.0206 9.93123 10.5381 9.53532C10.0556 9.13941 9.40113 8.91699 8.71875 8.91699H3.57292C2.89054 8.91699 2.23611 9.13941 1.75359 9.53532C1.27107 9.93123 1 10.4682 1 11.0281V12.0837" stroke="#9AA6B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);


export const PlanTag = (props) => (
  <svg className="PlanTag" xmlns="http://www.w3.org/2000/svg" width="32" height="41" viewBox="0 0 32 41" fill="none">
    <g filter="url(#filter0_d_1593_103)">
      <path d="M12 0H20V33L16 28.3013L12 33V0Z" fill="#92989F" />
    </g>
    <g filter="url(#filter1_d_1593_103)">
      <circle cx="16" cy="16" r="8" fill="#3463EE" />
    </g>
    <path d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.0049 17.255C14.0949 17.405 14.2049 17.545 14.3299 17.67C15.2499 18.59 16.7449 18.59 17.6699 17.67C18.0449 17.295 18.2599 16.82 18.3299 16.335" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.6699 15.6656C13.7399 15.1756 13.9549 14.7056 14.3299 14.3306C15.2499 13.4106 16.7449 13.4106 17.6699 14.3306C17.7999 14.4606 17.9049 14.6006 17.9949 14.7456" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.9102 18.5899V17.2549H15.2451" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.0899 13.4102V14.7451H16.7549" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
    <defs>
      <filter id="filter0_d_1593_103" x="8" y="0" width="16" height="41" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1593_103" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1593_103" result="shape" />
      </filter>
      <filter id="filter1_d_1593_103" x="0" y="1" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1593_103" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1593_103" result="shape" />
      </filter>
    </defs>
  </svg>
);

export const EditPen = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M10.3211 1.90789C9.06655 0.646322 7.84003 0.618287 6.55043 1.90789L5.75844 2.69286C5.68836 2.76295 5.66733 2.86107 5.69536 2.95219C6.18597 4.66932 7.55968 6.04303 9.27681 6.53363C9.29784 6.54064 9.33288 6.54064 9.35391 6.54064C9.424 6.54064 9.49408 6.51261 9.54314 6.46355L10.3211 5.67857C10.9589 5.04078 11.2743 4.43102 11.2743 3.80725C11.2743 3.17646 10.9589 2.55269 10.3211 1.90789Z" fill="white" />
    <path d="M8.10613 7.1239C7.9169 7.03279 7.73467 6.94167 7.56646 6.83654C7.42629 6.75244 7.28611 6.66132 7.15295 6.5632C7.04081 6.49312 6.91465 6.38798 6.7885 6.28285C6.77448 6.27585 6.73243 6.2408 6.67636 6.18473C6.45909 6.00951 6.2278 5.77122 6.01053 5.5119C5.99651 5.49788 5.95446 5.45583 5.91942 5.39275C5.84933 5.31565 5.7442 5.18249 5.65309 5.0353C5.57599 4.93718 5.48488 4.79701 5.40077 4.64983C5.29564 4.47461 5.20453 4.29939 5.12043 4.11716C5.02931 3.92092 4.95922 3.73869 4.89615 3.56348L1.12546 7.33416C0.880158 7.57946 0.641862 8.04204 0.592801 8.38547L0.291427 10.4741C0.228348 10.9156 0.347496 11.3291 0.620836 11.6025C0.852123 11.8337 1.16752 11.9529 1.51795 11.9529C1.59505 11.9529 1.67214 11.9459 1.74924 11.9389L3.83082 11.6445C4.17425 11.5955 4.63682 11.3642 4.88213 11.1119L8.65281 7.34117C8.47759 7.2851 8.30238 7.208 8.10613 7.1239Z" fill="white" />
  </svg>
);

export const CardEditIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.33337 1.33301H6.00004C2.66671 1.33301 1.33337 2.66642 1.33337 5.99994V10.0002C1.33337 13.3337 2.66671 14.6671 6.00004 14.6671H10C13.3334 14.6671 14.6667 13.3337 14.6667 10.0002V8.66676" stroke="#64748B" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.6934 2.01355L5.44004 7.26719C5.24004 7.4672 5.04004 7.86055 5.00004 8.14724L4.71337 10.154C4.60671 10.8807 5.12004 11.3874 5.84671 11.2874L7.85337 11.0007C8.13337 10.9607 8.52671 10.7607 8.73337 10.5607L13.9867 5.30707C14.8934 4.40035 15.32 3.34696 13.9867 2.01355C12.6534 0.680141 11.6 1.10683 10.6934 2.01355Z" stroke="#64748B" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.93994 2.7666C10.3866 4.36003 11.6333 5.60676 13.2333 6.06012" stroke="#64748B" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const TrashIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M14 3.98665C11.78 3.76665 9.54667 3.65332 7.32 3.65332C6 3.65332 4.68 3.71999 3.36 3.85332L2 3.98665" stroke="#5A697D" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.66663 3.31301L5.81329 2.43967C5.91996 1.80634 5.99996 1.33301 7.12663 1.33301H8.87329C9.99996 1.33301 10.0866 1.83301 10.1866 2.44634L10.3333 3.31301" stroke="#5A697D" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.5667 6.09375L12.1334 12.8071C12.06 13.8537 12 14.6671 10.14 14.6671H5.86002C4.00002 14.6671 3.94002 13.8537 3.86668 12.8071L3.43335 6.09375" stroke="#5A697D" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.88672 11H9.10672" stroke="#5A697D" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.33337 8.33301H9.66671" stroke="#5A697D" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const EmailIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
    <path d="M3.735 13.15H12.265C13.8761 13.15 15.15 11.6379 15.15 9.82721V4.17279C15.15 2.3622 13.8762 0.850127 12.265 0.85H3.735C2.12389 0.85 0.85 2.36221 0.85 4.17279V9.82721C0.85 11.6379 2.1239 13.15 3.735 13.15ZM1.74571 4.17276C1.74571 2.86554 2.65838 1.84116 3.735 1.84116H12.2651C13.3418 1.84116 14.2544 2.86554 14.2544 4.17276L14.2543 9.82718C14.2543 11.1344 13.3416 12.1588 12.265 12.1588H3.735C2.65828 12.1588 1.74571 11.1344 1.74571 9.82718V4.17276Z" fill="#64748B" stroke="#64748B" stroke-width="0.3" />
    <path d="M4.00425 3.77701L4.00427 3.77703L6.85268 6.13733C6.85268 6.13734 6.85268 6.13734 6.85269 6.13734C7.54094 6.70754 8.45991 6.70771 9.14811 6.13735L9.14812 6.13734L11.9965 3.77703C12.2069 3.60271 12.4965 3.6684 12.6355 3.89413L12.6355 3.89414C12.7673 4.10827 12.7299 4.40818 12.5343 4.57029L12.4386 4.45479L12.5343 4.57029L9.68585 6.9306C9.68585 6.9306 9.68585 6.9306 9.68585 6.9306C9.18447 7.34611 8.59372 7.5561 8.0004 7.5561C7.40708 7.5561 6.81633 7.34611 6.31495 6.9306L4.00425 3.77701ZM4.00425 3.77701C3.79407 3.60291 3.50442 3.66832 3.36537 3.89408L4.00425 3.77701ZM3.46653 4.57029L6.31495 6.9306L3.36533 3.89414C3.23333 4.10836 3.27098 4.40825 3.46653 4.57029C3.46653 4.57029 3.46653 4.57029 3.46653 4.57029Z" fill="#64748B" stroke="#64748B" stroke-width="0.3" />
  </svg>
);

export const PhoneIcon2 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.80324 5.43964C3.23186 8.55227 5.77405 11.0269 8.93432 12.3678L8.9443 12.372L9.55483 12.6443C10.3323 12.9909 11.246 12.7447 11.7445 12.0545L12.7631 10.6439C12.8253 10.5577 12.8093 10.4379 12.7266 10.3712L10.9484 8.93573C10.86 8.86437 10.73 8.881 10.6623 8.9723L9.97025 9.90675C9.80217 10.1337 9.49863 10.2125 9.24144 10.0961C6.87837 9.02604 4.97944 7.12601 3.91002 4.76156C3.79363 4.50422 3.87244 4.20051 4.09925 4.03233L5.03314 3.33984C5.12439 3.27217 5.141 3.14211 5.06969 3.05365L3.63489 1.27412C3.56821 1.19142 3.44851 1.17535 3.36241 1.23755L1.945 2.26139C1.25057 2.763 1.00601 3.68501 1.36047 4.46515L1.80276 5.4386C1.80292 5.43895 1.80308 5.4393 1.80324 5.43964ZM8.46121 13.4701C5.02736 12.0117 2.26546 9.32201 0.713162 5.93917L0.712227 5.93714L0.269009 4.96164C-0.321761 3.66141 0.0858294 2.12472 1.24321 1.28871L2.66062 0.264869C3.2634 -0.170533 4.10126 -0.0580812 4.56805 0.520869L6.00284 2.3004C6.50206 2.91956 6.38578 3.83002 5.74701 4.30368L5.21187 4.70049C6.11582 6.4555 7.54848 7.889 9.30248 8.79348L9.69907 8.25802C10.1725 7.61887 11.0824 7.50253 11.7012 8.00204L13.4794 9.43746C14.0582 9.90467 14.1705 10.7433 13.7349 11.3465L12.7163 12.7571C11.8856 13.9075 10.3626 14.3178 9.06688 13.7401L8.46121 13.4701Z" fill="#64748B" />
  </svg>
);

export const AdorementEmail = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#E4E4E4" stroke="#EBEAED" />
    <path d="M21.334 10.6665H10.6673C9.93398 10.6665 9.34065 11.2665 9.34065 11.9998L9.33398 19.9998C9.33398 20.7332 9.93398 21.3332 10.6673 21.3332H21.334C22.0673 21.3332 22.6673 20.7332 22.6673 19.9998V11.9998C22.6673 11.2665 22.0673 10.6665 21.334 10.6665ZM21.334 13.3332L16.0007 16.6665L10.6673 13.3332V11.9998L16.0007 15.3332L21.334 11.9998V13.3332Z" fill="#2E2C34" />
  </svg>
);

export const AdorementLock = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#E4E4E4" stroke="#EBEAED" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8707 13.1725L13.0994 15.2309L12.6366 15.2678C11.9912 15.3194 11.4642 15.8045 11.3596 16.4435C11.1184 17.9161 11.1184 19.4181 11.3596 20.8907C11.4642 21.5296 11.9912 22.0148 12.6366 22.0663L13.634 22.1459C15.2088 22.2716 16.791 22.2716 18.3658 22.1459L19.3632 22.0663C20.0086 22.0148 20.5356 21.5296 20.6402 20.8907C20.8814 19.4181 20.8814 17.9161 20.6402 16.4435C20.5356 15.8045 20.0086 15.3194 19.3632 15.2678L18.9003 15.2309L19.129 13.1725C19.156 12.9299 19.156 12.685 19.129 12.4423L19.1139 12.3057C18.9529 10.8568 17.8138 9.71035 16.3659 9.54001C16.1227 9.5114 15.877 9.5114 15.6338 9.54001C14.1859 9.71035 13.0469 10.8568 12.8859 12.3057L12.8707 12.4423C12.8437 12.685 12.8437 12.9299 12.8707 13.1725ZM16.2491 10.5332C16.0835 10.5137 15.9162 10.5137 15.7506 10.5332C14.7649 10.6491 13.9894 11.4297 13.8797 12.4161L13.8646 12.5527C13.8458 12.722 13.8458 12.8928 13.8646 13.0621L14.0971 15.1549C15.3643 15.0736 16.6354 15.0736 17.9026 15.1549L18.1352 13.0621C18.154 12.8928 18.154 12.722 18.1352 12.5527L18.12 12.4161C18.0104 11.4297 17.2349 10.6491 16.2491 10.5332ZM15.9999 17.6671C15.4476 17.6671 14.9999 18.1148 14.9999 18.6671C14.9999 19.2193 15.4476 19.6671 15.9999 19.6671C16.5522 19.6671 16.9999 19.2193 16.9999 18.6671C16.9999 18.1148 16.5522 17.6671 15.9999 17.6671Z" fill="black" />
  </svg>
);

export const AdorementUser = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#E4E4E4" stroke="#EBEAED" />
    <path d="M16.0007 10.5C14.6199 10.5 13.5007 11.6193 13.5007 13C13.5007 14.3807 14.6199 15.5 16.0007 15.5C17.3814 15.5 18.5007 14.3807 18.5007 13C18.5007 11.6193 17.3814 10.5 16.0007 10.5Z" fill="#2E2C34" />
    <path d="M13.334 16.8333C11.9533 16.8333 10.834 17.9526 10.834 19.3333V20.1255C10.834 20.6277 11.1979 21.0558 11.6935 21.1367C14.5461 21.6025 17.4553 21.6025 20.3078 21.1367C20.8034 21.0558 21.1673 20.6277 21.1673 20.1255V19.3333C21.1673 17.9526 20.048 16.8333 18.6673 16.8333H18.4401C18.3171 16.8333 18.1949 16.8528 18.0779 16.891L17.5009 17.0794C16.5261 17.3977 15.4752 17.3977 14.5004 17.0794L13.9234 16.891C13.8064 16.8528 13.6842 16.8333 13.5612 16.8333H13.334Z" fill="#2E2C34" />
  </svg>
);

export const BuildingIcon = (props) => (
  <svg className="BuildingIcon" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
    <path d="M8 3.25V0.25H0.5V13.75H15.5V3.25H8ZM3.5 12.25H2V10.75H3.5V12.25ZM3.5 9.25H2V7.75H3.5V9.25ZM3.5 6.25H2V4.75H3.5V6.25ZM3.5 3.25H2V1.75H3.5V3.25ZM6.5 12.25H5V10.75H6.5V12.25ZM6.5 9.25H5V7.75H6.5V9.25ZM6.5 6.25H5V4.75H6.5V6.25ZM6.5 3.25H5V1.75H6.5V3.25ZM14 12.25H8V10.75H9.5V9.25H8V7.75H9.5V6.25H8V4.75H14V12.25ZM12.5 6.25H11V7.75H12.5V6.25ZM12.5 9.25H11V10.75H12.5V9.25Z" fill={props.color ? props.color : '#84818A'} />
  </svg>
);

export const AdorementPhone = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#E4E4E4" stroke="#EBEAED" />
    <path d="M20.985 19.165C20.985 19.345 20.945 19.53 20.86 19.71C20.775 19.89 20.665 20.06 20.52 20.22C20.275 20.49 20.005 20.685 19.7 20.81C19.4 20.935 19.075 21 18.725 21C18.215 21 17.67 20.88 17.095 20.635C16.52 20.39 15.945 20.06 15.375 19.645C14.8 19.225 14.255 18.76 13.735 18.245C13.22 17.725 12.755 17.18 12.34 16.61C11.93 16.04 11.6 15.47 11.36 14.905C11.12 14.335 11 13.79 11 13.27C11 12.93 11.06 12.605 11.18 12.305C11.3 12 11.49 11.72 11.755 11.47C12.075 11.155 12.425 11 12.795 11C12.935 11 13.075 11.03 13.2 11.09C13.33 11.15 13.445 11.24 13.535 11.37L14.695 13.005C14.785 13.13 14.85 13.245 14.895 13.355C14.94 13.46 14.965 13.565 14.965 13.66C14.965 13.78 14.93 13.9 14.86 14.015C14.795 14.13 14.7 14.25 14.58 14.37L14.2 14.765C14.145 14.82 14.12 14.885 14.12 14.965C14.12 15.005 14.125 15.04 14.135 15.08C14.15 15.12 14.165 15.15 14.175 15.18C14.265 15.345 14.42 15.56 14.64 15.82C14.865 16.08 15.105 16.345 15.365 16.61C15.635 16.875 15.895 17.12 16.16 17.345C16.42 17.565 16.635 17.715 16.805 17.805C16.83 17.815 16.86 17.83 16.895 17.845C16.935 17.86 16.975 17.865 17.02 17.865C17.105 17.865 17.17 17.835 17.225 17.78L17.605 17.405C17.73 17.28 17.85 17.185 17.965 17.125C18.08 17.055 18.195 17.02 18.32 17.02C18.415 17.02 18.515 17.04 18.625 17.085C18.735 17.13 18.85 17.195 18.975 17.28L20.63 18.455C20.76 18.545 20.85 18.65 20.905 18.775C20.955 18.9 20.985 19.025 20.985 19.165Z" fill="#2E2C34" stroke="#2E2C34" stroke-miterlimit="10" />
  </svg>
);

export const PDFDownloadIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
    <path d="M0 9.59473V13.503C0 15.9496 2.00179 17.9514 4.44843 17.9514H15.6966C18.1432 17.9514 20.145 15.9496 20.145 13.503V9.59473H18.874V13.503C18.874 15.2506 17.4442 16.6804 15.6966 16.6804H4.44843C2.70083 16.6804 1.27098 15.2506 1.27098 13.503V9.59473H0Z" fill="#A4A4A4" />
    <path d="M9.43729 0V11.4706L5.37015 7.37168L4.48047 8.26137L9.65971 13.4406C9.78681 13.5677 9.94568 13.6313 10.1046 13.6313C10.2634 13.6313 10.4223 13.5677 10.5494 13.4406L15.7286 8.26137L14.7754 7.37168L10.7083 11.4706V0H9.43729Z" fill="#A4A4A4" />
  </svg>
);

export const MoreOption = (props) => (
  <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.77781 0C0.800016 0 0 0.825 0 1.83333C0 2.84167 0.800016 3.66667 1.77781 3.66667C2.75561 3.66667 3.55563 2.84167 3.55563 1.83333C3.55563 0.825 2.75561 0 1.77781 0Z" fill="#64748B" />
    <path d="M14.2221 0C13.2444 0 12.4443 0.825 12.4443 1.83333C12.4443 2.84167 13.2444 3.66667 14.2221 3.66667C15.1999 3.66667 16 2.84167 16 1.83333C16 0.825 15.1999 0 14.2221 0Z" fill="#64748B" />
    <path d="M8.00047 0C7.02267 0 6.22266 0.825 6.22266 1.83333C6.22266 2.84167 7.02267 3.66667 8.00047 3.66667C8.97827 3.66667 9.77828 2.84167 9.77828 1.83333C9.77828 0.825 8.97827 0 8.00047 0Z" fill="#64748B" />
  </svg>);

export const SortIcon = (props) => (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.39775 0.727252C7.61742 0.946922 7.61742 1.30308 7.39775 1.52275L4.39775 4.52275C4.17808 4.74242 3.82192 4.74242 3.60225 4.52275L0.602252 1.52275C0.382583 1.30308 0.382583 0.946923 0.602252 0.727253C0.821922 0.507583 1.17808 0.507583 1.39775 0.727253L4 3.3295L6.60225 0.727252C6.82192 0.507583 7.17808 0.507583 7.39775 0.727252Z" fill="#64748B" />
  </svg>);

export const SortIconDown = (props) => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.23414 6.30341C7.31554 6.21387 7.42155 6.16984 7.52897 6.16984C7.63376 6.16984 7.74566 6.21207 7.82494 6.30467C7.97931 6.47583 7.97893 6.75314 7.8238 6.92378L5.29463 9.70586C5.13412 9.88242 4.86549 9.88242 4.70498 9.70586L2.17581 6.92378C2.0203 6.75272 2.0203 6.47447 2.17581 6.30341C2.33632 6.12685 2.60496 6.12685 2.76547 6.30341L4.9998 8.76118L7.23414 6.30341Z" fill="#292D32" stroke="#292D32" stroke-width="0.2" />
    <path d="M4.5875 1.60461C4.5875 1.37065 4.76518 1.16086 5 1.16086C5.23482 1.16086 5.4125 1.37065 5.4125 1.60461L5.4125 9.31836C5.4125 9.55232 5.23482 9.76211 5 9.76211C4.76518 9.76211 4.5875 9.55232 4.5875 9.31836L4.5875 1.60461Z" fill="#292D32" stroke="#292D32" stroke-width="0.2" />
  </svg>
);

export const SortIconUp = (props) => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.23414 4.69659C7.31554 4.78613 7.42155 4.83016 7.52897 4.83016C7.63376 4.83016 7.74566 4.78793 7.82494 4.69533C7.97931 4.52417 7.97893 4.24686 7.8238 4.07622L5.29463 1.29414C5.13412 1.11758 4.86549 1.11758 4.70498 1.29414L2.17581 4.07622C2.0203 4.24728 2.0203 4.52553 2.17581 4.69659C2.33632 4.87315 2.60496 4.87315 2.76547 4.69659L4.9998 2.23882L7.23414 4.69659Z" fill="#292D32" stroke="#292D32" stroke-width="0.2" />
    <path d="M4.5875 9.39539C4.5875 9.62935 4.76518 9.83914 5 9.83914C5.23482 9.83914 5.4125 9.62935 5.4125 9.39539V1.68164C5.4125 1.44768 5.23482 1.23789 5 1.23789C4.76518 1.23789 4.5875 1.44768 4.5875 1.68164V9.39539Z" fill="#292D32" stroke="#292D32" stroke-width="0.2" />
  </svg>);

export const FilterIcon = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.58342 1.16699H12.4168C12.5368 1.20908 12.6456 1.27808 12.7348 1.36871C12.824 1.45934 12.8913 1.56919 12.9315 1.68984C12.9718 1.8105 12.9838 1.93876 12.9668 2.0648C12.9498 2.19084 12.9042 2.31131 12.8334 2.41699L8.66676 7.00033V12.8337L5.33342 10.3337V7.00033L1.16676 2.41699C1.096 2.31131 1.05037 2.19084 1.03336 2.0648C1.01636 1.93876 1.02842 1.8105 1.06864 1.68984C1.10886 1.56919 1.17616 1.45934 1.26539 1.36871C1.35462 1.27808 1.46341 1.20908 1.58342 1.16699Z" stroke="#9DA9BA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>);




export const ChevronLeft = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9" fill="none">
    <path d="M4.2775 8.5575C4.0875 8.5575 3.8975 8.4875 3.7475 8.3375L0.2175 4.8075C-0.0725 4.5175 -0.0725 4.0375 0.2175 3.7475L3.7475 0.2175C4.0375 -0.0725 4.5175 -0.0725 4.8075 0.2175C5.0975 0.5075 5.0975 0.9875 4.8075 1.2775L1.8075 4.2775L4.8075 7.2775C5.0975 7.5675 5.0975 8.0475 4.8075 8.3375C4.6675 8.4875 4.4775 8.5575 4.2775 8.5575Z" fill="#64748B" />
  </svg>
);

export const ViewAllIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path d="M10.3335 8.53415L10.5371 3.53079L5.53379 3.32718" stroke="#2563EB" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3.24567 10.252L10.4642 3.59799" stroke="#2563EB" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const BackArrowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
    <path d="M7.57 0.929993L1.5 6.99999L7.57 13.07" stroke="#2A2A2A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.5 7H1.67" stroke="#2A2A2A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const DoubleCircle = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
    <circle cx="7.43872" cy="6.99976" r="6.5" stroke="black" stroke-opacity="0.34" />
    <path d="M4.76199 4.50285C4.65638 4.47446 4.56859 4.39973 4.52239 4.29887C4.47618 4.19802 4.47632 4.08146 4.52277 3.98073L5.33775 2.21335C5.39848 2.08164 5.5285 1.99762 5.67131 1.9978C5.81412 1.99798 5.94393 2.08233 6.00434 2.21419L6.36386 2.99889C6.37672 2.99374 6.38999 2.98928 6.40364 2.98555C8.537 2.40305 10.7298 3.69314 11.3015 5.86704C11.8731 8.04094 10.6071 10.2754 8.47371 10.8579C6.34035 11.4404 4.14752 10.1503 3.57589 7.97644C3.39411 7.28515 3.3982 6.58663 3.55741 5.9372C3.60667 5.73625 3.80647 5.61404 4.00368 5.66424C4.20088 5.71444 4.32082 5.91804 4.27155 6.11899C4.14186 6.64804 4.13828 7.21713 4.28689 7.78231C4.75331 9.55607 6.54251 10.6087 8.28319 10.1334C10.0239 9.65814 11.0569 7.83493 10.5905 6.06118C10.1317 4.31642 8.39299 3.2694 6.67957 3.68797L7.04232 4.47971C7.10273 4.61157 7.08283 4.76713 6.99127 4.87881C6.89971 4.99049 6.75302 5.03811 6.61494 5.00099L4.76199 4.50285Z" fill="#64748B" />
  </svg>
);

export const CopyIcon = (props) => (
  <svg class="CopyIcon" fill={props.fill || '#7e84a3'} width={props.width || "10"} height={props.height || "10"} focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path></svg>
);

// export const InvoiceIcon = (props) => {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
//       <path d="M4.20625 12.3125C4.71875 11.7625 5.5 11.8062 5.95 12.4062L6.58125 13.25C7.0875 13.9187 7.90625 13.9187 8.4125 13.25L9.04375 12.4062C9.49375 11.8062 10.275 11.7625 10.7875 12.3125C11.9 13.5 12.8062 13.1062 12.8062 11.4437V4.4C12.8125 1.88125 12.225 1.25 9.8625 1.25H5.1375C2.775 1.25 2.1875 1.88125 2.1875 4.4V11.4375C2.1875 13.1062 3.1 13.4937 4.20625 12.3125Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
//       <path d="M5.06004 6.875H5.06566" stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
//       <path d="M6.81152 6.875H10.249" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
//       <path d="M5.06004 4.375H5.06566" stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
//       <path d="M6.81152 4.375H10.249" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
//     </svg>
//   )
// }

export const TaskIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2507 2.06262H5.74899C3.48324 2.06262 2.06274 3.66687 2.06274 5.93712V12.0631C2.06274 14.3334 3.47649 15.9376 5.74899 15.9376H12.25C14.5232 15.9376 15.9377 14.3334 15.9377 12.0631V5.93712C15.9377 3.66687 14.5232 2.06262 12.2507 2.06262Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.32983 9.00009L8.11033 10.7798L11.6698 7.22034" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const DocsIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M10.5 2.25V5.25C10.5 5.44891 10.579 5.63968 10.7197 5.78033C10.8603 5.92098 11.0511 6 11.25 6H14.25" stroke="#778599" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.75 15.75H5.25C4.85218 15.75 4.47064 15.592 4.18934 15.3107C3.90804 15.0294 3.75 14.6478 3.75 14.25V3.75C3.75 3.35218 3.90804 2.97064 4.18934 2.68934C4.47064 2.40804 4.85218 2.25 5.25 2.25H10.5L14.25 6V14.25C14.25 14.6478 14.092 15.0294 13.8107 15.3107C13.5294 15.592 13.1478 15.75 12.75 15.75Z" stroke="#778599" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.75 6.75H7.5" stroke="#778599" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.75 9.75H11.25" stroke="#778599" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.75 12.75H11.25" stroke="#778599" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const ClientIcon = (props) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.001 14.944V13.8885C13.001 13.3286 12.7299 12.7916 12.2474 12.3957C11.7649 11.9998 11.1105 11.7773 10.4281 11.7773H5.28227C4.59989 11.7773 3.94546 11.9998 3.46294 12.3957C2.98043 12.7916 2.70935 13.3286 2.70935 13.8885V14.944" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.45935 8.61072C8.77103 8.61072 9.83435 7.54739 9.83435 6.23572C9.83435 4.92404 8.77103 3.86072 7.45935 3.86072C6.14767 3.86072 5.08435 4.92404 5.08435 6.23572C5.08435 7.54739 6.14767 8.61072 7.45935 8.61072Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.1677 14.944V13.8651C16.1673 13.387 16.0116 12.9225 15.7251 12.5446C15.4385 12.1668 15.0373 11.8969 14.5844 11.7773" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.2094 3.86072C12.6623 3.99574 13.0637 4.30244 13.3504 4.73246C13.6371 5.16248 13.7927 5.69136 13.7927 6.23572C13.7927 6.78008 13.6371 7.30896 13.3504 7.73898C13.0637 8.169 12.6623 8.47569 12.2094 8.61072" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const FlowIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M16.2451 7.82988L15.5101 10.9649C14.8801 13.6724 13.6351 14.7674 11.2951 14.5424C10.9201 14.5124 10.5151 14.4449 10.0801 14.3399L8.82014 14.0399C5.69263 13.2974 4.72513 11.7524 5.46013 8.61738L6.19513 5.47488C6.34513 4.83738 6.52513 4.28238 6.75013 3.82488C7.62763 2.00988 9.12013 1.52238 11.6251 2.11488L12.8776 2.40738C16.0201 3.14238 16.9801 4.69488 16.2451 7.82988Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.2957 14.5425C10.8307 14.8575 10.2457 15.12 9.53324 15.3525L8.34824 15.7425C5.37074 16.7025 3.80324 15.9 2.83574 12.9225L1.87574 9.95995C0.915744 6.98245 1.71074 5.40745 4.68824 4.44745L5.87324 4.05745C6.18074 3.95995 6.47324 3.87745 6.75074 3.82495C6.52574 4.28245 6.34574 4.83745 6.19574 5.47495L5.46074 8.61745C4.72574 11.7525 5.69324 13.2975 8.82074 14.04L10.0807 14.34C10.5157 14.445 10.9207 14.5125 11.2957 14.5425Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.30578 8.25023L10.4227 7.33003C10.5909 7.25632 10.7595 7.341 10.8069 7.51907L11.1376 8.78868C11.1849 8.96674 11.3551 9.04603 11.5217 8.97771L13.6386 8.05751" stroke="#64748B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const BoardIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <rect x="0.75" y="0.75" width="13.5" height="13.5" rx="2.25" stroke="#64748B" stroke-width="1.5" />
      <rect x="0.789062" y="5.5271" width="4.73684" height="0.394737" rx="0.197368" transform="rotate(-90 0.789062 5.5271)" fill="#64748B" />
      <rect x="3.85" y="12.15" width="9.3" height="1.3" rx="0.65" transform="rotate(-90 3.85 12.15)" fill="#64748B" stroke="#64748B" stroke-width="0.3" />
      <rect x="9.85" y="7.15" width="4.3" height="1.3" rx="0.65" transform="rotate(-90 9.85 7.15)" fill="#64748B" stroke="#64748B" stroke-width="0.3" />
      <rect x="6.85" y="10.15" width="7.3" height="1.3" rx="0.65" transform="rotate(-90 6.85 10.15)" fill="#64748B" stroke="#64748B" stroke-width="0.3" />
    </svg>
  )
}
export const AgencyListIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5665 7.15035V12.8503C17.5665 13.7837 17.0665 14.6504 16.2581 15.1254L11.3081 17.9837C10.4998 18.4504 9.4998 18.4504 8.68314 17.9837L3.73314 15.1254C2.9248 14.6587 2.4248 13.792 2.4248 12.8503V7.15035C2.4248 6.21702 2.9248 5.35032 3.73314 4.87532L8.68314 2.01699C9.49147 1.55033 10.4915 1.55033 11.3081 2.01699L16.2581 4.87532C17.0665 5.35032 17.5665 6.20869 17.5665 7.15035Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.99977 9.16651C11.0721 9.16651 11.9414 8.29719 11.9414 7.22483C11.9414 6.15248 11.0721 5.2832 9.99977 5.2832C8.92742 5.2832 8.05811 6.15248 8.05811 7.22483C8.05811 8.29719 8.92742 9.16651 9.99977 9.16651Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.3332 13.8836C13.3332 12.3836 11.8415 11.167 9.99984 11.167C8.15817 11.167 6.6665 12.3836 6.6665 13.8836" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
export const UsersIcon1 = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9999 5.96663C14.9499 5.9583 14.8916 5.9583 14.8416 5.96663C13.6916 5.92496 12.7749 4.98329 12.7749 3.81663C12.7749 2.62496 13.7332 1.66663 14.9249 1.66663C16.1166 1.66663 17.0749 2.63329 17.0749 3.81663C17.0666 4.98329 16.1499 5.92496 14.9999 5.96663Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.1417 12.0333C15.2834 12.225 16.5417 12.025 17.425 11.4333C18.6 10.65 18.6 9.36664 17.425 8.58331C16.5334 7.99164 15.2584 7.79163 14.1167 7.99163" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.97539 5.96663C5.02539 5.9583 5.08372 5.9583 5.13372 5.96663C6.28372 5.92496 7.20039 4.98329 7.20039 3.81663C7.20039 2.62496 6.24206 1.66663 5.05039 1.66663C3.85873 1.66663 2.90039 2.63329 2.90039 3.81663C2.90872 4.98329 3.82539 5.92496 4.97539 5.96663Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.83353 12.0333C4.69186 12.225 3.43353 12.025 2.5502 11.4333C1.3752 10.65 1.3752 9.36664 2.5502 8.58331C3.44186 7.99164 4.71686 7.79163 5.85853 7.99163" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.9999 12.1917C9.9499 12.1834 9.89157 12.1834 9.84157 12.1917C8.69157 12.1501 7.7749 11.2084 7.7749 10.0417C7.7749 8.85006 8.73323 7.89172 9.9249 7.89172C11.1166 7.89172 12.0749 8.85839 12.0749 10.0417C12.0666 11.2084 11.1499 12.1584 9.9999 12.1917Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.5751 14.8167C6.4001 15.6 6.4001 16.8833 7.5751 17.6667C8.90843 18.5583 11.0918 18.5583 12.4251 17.6667C13.6001 16.8833 13.6001 15.6 12.4251 14.8167C11.1001 13.9333 8.90843 13.9333 7.5751 14.8167Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
export const InvoiceIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3335 5.00033V7.01699C18.3335 8.33366 17.5002 9.16699 16.1835 9.16699H13.3335V3.34199C13.3335 2.41699 14.0918 1.66699 15.0168 1.66699C15.9252 1.67533 16.7585 2.04199 17.3585 2.64199C17.9585 3.25032 18.3335 4.08366 18.3335 5.00033Z" stroke="#64748B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.6665 5.83366V17.5003C1.6665 18.192 2.44984 18.5837 2.99984 18.167L4.42484 17.1003C4.75817 16.8503 5.22484 16.8837 5.52484 17.1837L6.90817 18.5753C7.23317 18.9003 7.7665 18.9003 8.0915 18.5753L9.4915 17.1753C9.78317 16.8837 10.2498 16.8503 10.5748 17.1003L11.9998 18.167C12.5498 18.5753 13.3332 18.1837 13.3332 17.5003V3.33366C13.3332 2.41699 14.0832 1.66699 14.9998 1.66699H5.83317H4.99984C2.49984 1.66699 1.6665 3.15866 1.6665 5.00033V5.83366Z" stroke="#64748B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.5 10.8418H10" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.5 7.50879H10" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.99609 10.833H5.00358" stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.99609 7.5H5.00358" stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const MailIcon1 = (props) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.75 7.4375C1.75 4.375 3.5 3.0625 6.125 3.0625H14.875C17.5 3.0625 19.25 4.375 19.25 7.4375V13.5625C19.25 16.625 17.5 17.9375 14.875 17.9375H6.125" stroke="#64748B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.875 7.875L12.1362 10.0625C11.235 10.78 9.75625 10.78 8.855 10.0625L6.125 7.875" stroke="#64748B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.75 14.4375H7" stroke="#64748B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.75 10.9375H4.375" stroke="#64748B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);





