import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import AgencyDetail from "../containers/Agencies/AgencyDetail";
import Sales from "../containers/Sales";

const salesRoutes = [
    {
        path: '/sales',
        element: <ProtectedRoute />,
        children: [
            {
                path: 'customerpayments',
                element: <Sales />
            },
            {
                path: ':agency_id',
                element: <AgencyDetail />
            }
        ]
    }
]

export default salesRoutes
