import {
  Avatar,
  Button,
  Grid,
  Select,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AgenciICon,
  BoardIcon,
  CheckFilled,
  ClientIcon,
  DocsIcon,
  DownloadIcon,
  FilledEmailIcon,
  FlowIcon,
  InfiniteIcon,
  InvoiceIcon,
  PhoneIcon,
  PinIcon,
  PlanTag,
  ProDimond,
  ShareCircleIcon,
  TaskIcon,
  UserIcon,
  UsersIcon
} from "../../assets/svg";
import { getAgenciesDetail } from "../../store/actions/agenciesAction";
import ChangePlan from "./ChangePlan";
import PaymentCard from "./PaymentCard";
import Style from "./Style";

const AgencyDetail = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  let { agency_id } = useParams();
  const {
    agencydetail: { name, email, address, mobile_no, category, plan_name, price, status, expiry, agency_logo, username, clientCount
    }
  } = useSelector((state) => state.agencies)

  useEffect(() => {
    dispatch(getAgenciesDetail(agency_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency_id]);

  const navigate = useNavigate()

  const oneClient = clientCount && clientCount.find((count) => count.client === 0);
  console.log("oneClient", oneClient?.client_Count)
  const noClients = clientCount && clientCount.find((count) => count.client === 1);
  console.log("noClients", noClients?.client_Count)

  const cardData = [
    {
      name: "Infinity AI",
      icon: <InfiniteIcon />,
      description:
        "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
      switchText: "Active since 12 Jun 2023",
    },
    {
      name: "Infinity AI",
      icon: <ShareCircleIcon />,
      description:
        "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
      switchText: "Active since 12 Jun 2023",
    },
    {
      name: "Infinity AI",
      icon: <ShareCircleIcon />,
      description:
        "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
      switchText: "Active since 12 Jun 2023",
    },
    {
      name: "Infinity AI",
      icon: <ShareCircleIcon />,
      description:
        "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
      switchText: "Active since 12 Jun 2023",
    },
  ];
  return (
    <Grid container className={className}>
      {/* Agenci detil Header */}
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        className="breadcrumb"
      >
        <Grid className="MenuIcon">
          <Grid className="dflex aCenter" style={{ marginRight: '10px' }}>
            <InvoiceIcon />
            <Typography variant="body1" style={{ marginLeft: '5px' }}>
              Invoice
            </Typography>
          </Grid>
          <Grid className="dflex aCenter" style={{ marginRight: '10px' }}>
            <TaskIcon />
            <Typography variant="body1" style={{ marginLeft: '5px' }}>
              Tasks
            </Typography>
          </Grid>
          <Grid className="dflex aCenter" style={{ marginRight: '10px' }}>
            <DocsIcon />
            <Typography variant="body1" style={{ marginLeft: '5px' }}>
              Documents
            </Typography>
          </Grid>
          <Grid className="dflex aCenter" style={{ marginRight: '10px' }}>
            <BoardIcon />
            <Typography variant="body1" style={{ marginLeft: '5px' }}>
              Board
            </Typography>
          </Grid>
          <Grid className="dflex aCenter" style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => navigate(`./clients`)}>
            <ClientIcon />
            <Typography variant="body1" style={{ marginLeft: '5px' }}>
              Clients
            </Typography>
          </Grid>
          <Grid className="dflex aCenter" style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => navigate(`./flows`)}>
            <FlowIcon />
            <Typography variant="body1" style={{ marginLeft: '5px' }}>
              Flows
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid className="dflex aCenter">
          <Typography variant="h5" className="fw-700" color={"text.greyText2"}>
            Agency {" > "}
          </Typography>
          <Typography variant="h5" className="fw-700">
            Agency Info
          </Typography>
        </Grid> */}
        {/* Create payment */}
        {/* <CreatePaymentLink /> */}
      </Grid>
      {/* Agenci detil Header */}
      {/* Agency Card */}
      <Grid item xs={12} className={`agenciDetailCard`}>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item className="">
            <Grid item className="dflex aStart">
              <Grid item className="agenciName">
                <Grid>
                  <Avatar src={agency_logo} />
                </Grid>
                <Grid sx={{ ml: 2 }}>
                  <Typography
                    variant="h4"
                    className="fw-600"
                    sx={{ mb: 1 }}
                    color={"text.primaryText"}
                  >
                    {name}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="dflex"
                    color={"text.greyText2"}
                  >
                    <AgenciICon />
                    <Grid sx={{ ml: 1 }}>{category}</Grid>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sx={{ ml: 3 }}>
                <Grid className="agenciStatus chipItem">
                  <CheckFilled />
                  <Typography
                    sx={{ ml: 1 }}
                    variant="body2"
                    color={"text.primaryText"}
                  >
                    {status}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* Tags */}
            <Grid container className="agenciTags">
              <Grid item className="tagesChip">
                <Typography variant="body1" color={"text.blueText"}>
                  UI/UX Design
                </Typography>
              </Grid>
            </Grid>
            {/* Tags */}
          </Grid>

          {/* <Grid item>
            <Grid className="agenciDate chipItem">
              <AlertIcon />
              <Typography variant="body2" color={"text.redText"} sx={{ ml: 1 }}>
                Expires by
              </Typography>
              <Typography
                variant="body2"
                color={"text.greyText1"}
                sx={{ ml: 2 }}
              >
                12 June 23{" "}
              </Typography>
            </Grid>
          </Grid> */}

          {/* Plan Card */}
          <Grid item className="planCard">
            <PlanTag />
            <Grid item className="agenciPlan chipItem">
              <ProDimond />
              <Typography
                variant="body1"
                className="ml-1 fw-600"
                color={"text.primaryText"}
              >
                {plan_name}
              </Typography>
              <Grid className="dflex aBaseline">
                <Typography
                  variant="h1"
                  color={"text.primaryText"}
                  className="fw-700 ml-3"
                >
                  ${price}
                </Typography>
                <Typography
                  variant="body2"
                  color={"text.primaryText"}
                  className="pl5"
                >
                  USD/Month
                </Typography>
              </Grid>
            </Grid>
            <Grid className="dflex aCenter mb-1">
              <Typography variant="body2" color={"text.greyText3"}>
                Expiry Invoice On:
              </Typography>
              <Typography variant="body2" color={"textSecondary"}>
                {expiry}
              </Typography>
            </Grid>
            <Grid className="dflex aCenter jSpaceBetween">
              <ChangePlan />
              <Grid className="dflex aCenter">
                <Typography variant="body2" color={"text.greyText3"}>
                  Coupon Code :
                </Typography>
                <Typography
                  variant="body2"
                  className="fw-600"
                  color={"textSecondary"}
                >
                  AF@#@$
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* Plan Card */}
        </Grid>

        <Grid container justifyContent={"space-between"}>
          {/* Users Count */}
          <Grid item>
            <Grid container className="usersCount">
              <Grid item className="usersCountChip">
                <Grid className="UsersIcon">
                  <UsersIcon />
                </Grid>
                <Typography
                  variant="body1"
                  className="fw-600 ml-1"
                  color={"text.greyText1"}
                >
                  Clients
                </Typography>
                <Grid className="dflex aBaseline ml-2">
                  <Typography
                    variant="body1"
                    className="fw-600"
                    color={"text.blueText"}
                  >
                    {oneClient?.client_Count}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="fs-10 pl5"
                    color={"text.greyText1"}
                  >
                    nos
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className="usersCountChip ml-3">
                <Grid className="UsersIcon">
                  <UserIcon />
                </Grid>
                <Typography
                  variant="body1"
                  className="fw-600 ml-1"
                  color={"text.greyText1"}
                >
                  Users
                </Typography>
                <Grid className="dflex aBaseline ml-2">
                  <Typography
                    variant="body1"
                    className="fw-600"
                    color={"text.blueText"}
                  >
                    {noClients?.client_Count}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="fs-10 pl5"
                    color={"text.greyText1"}
                  >
                    nos
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Users Count */}

          {/* transaction card */}
          <Grid item className="transactionCard">
            <Grid className="mr-3">
              <Typography
                variant="body2"
                className="fs-10"
                color={"text.greyText3"}
              >
                Total Transaction
              </Typography>
              <Typography variant="h4" className="" color={"text.blueText"}>
                $292321
              </Typography>
            </Grid>
            <Select size="small" />
          </Grid>
          {/* transaction card */}
        </Grid>

        <Grid container className="agenciContact" spacing={5}>
          <Grid item className="dflex pt-3">
            <Grid className="leftItem" sx={{ mr: 2 }}>
              <FilledEmailIcon />
            </Grid>
            <Grid>
              <Typography variant="body2" color={"text.greyText3"}>
                Email
              </Typography>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                {email}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="dflex pt-3">
            <Grid className="leftItem" sx={{ mr: 2 }}>
              <PhoneIcon />
            </Grid>
            <Grid>
              <Typography variant="body2" color={"text.greyText3"}>
                Phone
              </Typography>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                {mobile_no}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="dflex pt-3">
            <Grid className="leftItem" sx={{ mr: 2 }}>
              <PinIcon />
            </Grid>
            <Grid>
              <Typography variant="body2" color={"text.greyText3"}>
                Address
              </Typography>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                {address}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="dflex pt-3">
            <Grid sx={{ mr: 2 }}>
              <Avatar />
            </Grid>
            <Grid>
              <Typography variant="body2" color={"text.greyText3"}>
                Owner
              </Typography>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                {username}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* // Agency Card */}

      {/* Agenci card */}
      <Grid item xs={12}>
        <Grid container className="paymentCardRoot" spacing={3}>
          {cardData.map((item) => (
            <Grid item xs={12} sm={6} md={3}>
              <PaymentCard
                icon={item.icon}
                name={item.name}
                description={item.description}
                switchText={item.switchText}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* Agenci card */}

      {/* Agenci Payments */}
      <Grid item xs={12} sx={{ pt: 3 }}>
        <Grid container className="agencyPayments">
          <Grid item xs={12} className="top">
            <Grid container justifyContent={"space-between"}>
              <Typography
                variant="h5"
                className="fw-700"
                color={"text.primaryText"}
              >
                Agency Payments
              </Typography>
              <Button
                variant="text"
                size="small"
                startIcon={<DownloadIcon />}
                color="secondary"
              >
                Download
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} className="pl-1 pr-1">
            {/* <AgencyPayment /> */}
          </Grid>
          <Grid item xs={12} className="pl-1 pr-1 pt-2">
            {/* <Table /> */}
          </Grid>
        </Grid>
      </Grid>
      {/* // Agenci Payments */}
    </Grid>
  );
};

export default styled(AgencyDetail)(Style);
// const AgencyDetail = styled(AgencyDetailComponent)(({ theme }) => Style(theme));

// export default AgencyDetail;
