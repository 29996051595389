// import { createSlice } from "@reduxjs/toolkit";
// import { getAgenciesPayment } from "../actions/agenciesAction";

// const initialState = {
//     list: {
//         results: [],
//         headers: [],
//         pagination: {
//             count: 0,
//             next: null,
//             previous: null,
//             page_size: 10,
//             num_pages: 0,
//             page_number: 1,
//         },
//         search: "",
//         filter: {
//             Status: [],
//             name: {},
//             Price: [0, 100],
//             date: {},
//         },
//         ordering: "invoice_id",
//         orderDirection: "desc",
//         orderBy: [
//             { field: "invoice_id", label: "Invoice No" },
//             { field: "invoice_amount", label: "Amount" },
//             { field: "status", label: "Status" },
//             { field: "expiry", label: "Duration" }
//         ],
//     },
//     agenciesPayment: {},
//     loading: null,
// };

// export const agenciesSlice = createSlice({
//     name: "agenciesPayment",
//     initialState,
//     reducers: {
//         setPageSize(state, action) {
//             state.list.pagination.page_size = action.payload;
//         },
//         setPage(state, action) {
//             state.list.pagination.page_number = action.payload;
//         },
//         setSearch(state, action) {
//             state.list.pagination.page_number = 1;
//             state.list.search = action.payload;
//         },
//         setOrdering(state, action) {
//             state.list.ordering = action.payload;
//         },
//         setOrderDirection(state, action) {
//             state.list.orderDirection = action.payload;
//         },

//         addFilterRule(state, action) {
//             state.list.filter.rules.push({
//                 index: (Math.max(state.list.filter.rules.map((m) => m.index)) || 0) + 1,
//                 field: "",
//                 operator: "",
//                 value: "",
//             });
//         },
//         removeFilterRule(state, action) {
//             state.list.filter.rules = state.list.filter.rules.filter(
//                 (f) => f.index !== action.payload
//             );
//         },
//         updateFilterRule(state, action) {
//             state.list.filter.rules = state.list.filter.rules.map((m) => {
//                 if (m.index === action.payload.index) {
//                     return action.payload;
//                 }
//                 return m;
//             });
//         },
//         applyFilter(state, action) {
//             const title = action?.payload?.title
//             state.list.pagination.page_number = 1;
//             state.list.filter[title] = action?.payload?.data;
//         },
//         clearFilter(state) {
//             state.list.pagination.page_number = 1;
//             state.list.filter.Status = [];
//             state.list.filter.Price = [0, 100];
//         },
//     },
//     extraReducers: (builder) => {
//         // add cases for getAgencies api calls
//         builder
//             .addCase(getAgenciesPayment.pending, (state) => {
//                 state.loading = true;
//             })
//             .addCase(getAgenciesPayment.fulfilled, (state, { payload }) => {
//                 state.list = {
//                     ...state.list,
//                     ...payload,
//                 };
//                 state.loading = false;
//             })
//             .addCase(getAgenciesPayment.rejected, (state) => {
//                 state.loading = false;
//             });
//         // add cases for other api calls
//         // like builder.addCase
//     },
// });

// export const {
//     setPage,
//     setPageSize,
//     setSearch,
//     setOrdering,
//     setOrderDirection,
//     addFilterRule,
//     removeFilterRule,
//     updateFilterRule,
//     applyFilter,
//     clearFilter,
// } = agenciesSlice.actions;

// export default agenciesSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { getAgenciesPayment } from "../actions/agenciesAction";

const initialState = {
    list: {
        results: [],
        headers: [],
        pagination: {
            count: 0,
            next: null,
            previous: null,
            page_size: 5,
            num_pages: 0,
            page_number: 1,
        },
        search: "",
        filter: {
            params: "",
            rules: [{ index: 0, field: "", operator: "", value: "" }],
        },
        ordering: "invoice_id",
        orderBy: [{ field: "invoice_id", label: "invoice no" }, { field: "invoice_amount", label: "Amount" }, { field: "status", label: "status" }, { field: "expiry", label: "Duration" }, { field: "agency_name", label: "Name" }]
    },
    agenciesPayment: {},
    loading: null,
};


export const agenciesPayment = createSlice({
    name: "agenciesPayment",
    initialState,
    reducers: {
        setPageSize(state, action) {
            state.list.pagination.page_size = action.payload;
        },
        setPage(state, action) {
            state.list.pagination.page_number = action.payload;
        },
        setSearch(state, action) {
            state.list.pagination.page_number = 1;
            state.list.search = action.payload;
        },
        setOrdering(state, action) {
            state.list.ordering = action.payload;
        },
        setOrderDirection(state, action) {
            state.list.orderDirection = action.payload;
        },
        addFilterRule(state, action) {
            state.list.filter.rules.push({
                index: (Math.max(state.list.filter.rules.map((m) => m.index)) || 0) + 1,
                field: "",
                operator: "",
                value: "",
            });
        },
        removeFilterRule(state, action) {
            state.list.filter.rules = state.list.filter.rules.filter(
                (f) => f.index !== action.payload
            );
        },
        updateFilterRule(state, action) {
            state.list.filter.rules = state.list.filter.rules.map((m) => {
                if (m.index === action.payload.index) {
                    return action.payload;
                }
                return m;
            });
        },
        applyFilter(state, action) {
            state.list.pagination.page_number = 1;
            state.list.filter.params = action.payload;
        },
        clearFilter(state) {
            state.list.pagination.page_number = 1;
            state.list.filter.params = "";
            state.list.filter.rules = [{ index: 0, field: "", value: "" }];
        },
        applyList(state, action) {
            state.list.results = action.payload
        }
    },
    extraReducers: (builder) => {
        // add cases for getCustomerPayement api calls
        builder
            .addCase(getAgenciesPayment.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAgenciesPayment.fulfilled, (state, { payload }) => {

                state.list = {
                    ...state.list,
                    // ...payload,
                    results: payload.data,
                    pagination: payload.pagination,
                    search: payload.search,
                    ordering: payload.orderings

                };
                state.loading = false;
            })
            .addCase(getAgenciesPayment.rejected, (state) => {
                state.loading = false;
            });
        // add cases for other api calls
        // like builder.addCase

    },
});

export const {
    setPage,
    setPageSize,
    setSearch,
    setOrdering,
    setOrderDirection,
    addFilterRule,
    removeFilterRule,
    updateFilterRule,
    applyFilter,
    clearFilter,
    applyList
} = agenciesPayment.actions;

export default agenciesPayment.reducer;


