import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  IconButton,
  Popover,
  Typography,
  styled,
} from "@mui/material";

import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
} from "../../store/reducers/userManagementReducer";
import Style from "./style";

//import UserCard from './Card'
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/actions/userAction";
import Table from "../../components/Table";
import { useNavigate } from "react-router-dom";
import { MoreOption } from "../../assets/svg";

const Users = (props) => {
  const { className } = props;
  const { loading, list } = useSelector((state) => state.userManagement);
  const { pagination, search, ordering, orderBy, filter } = list;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    let keysearch = "";
    let filters = { ordering }
    const queryParams = `?page_size=${pagination?.page_size}&page=${pagination?.page_number}${keysearch}`;
    if (search) {
      keysearch = `&search=${search}`;
    }

    dispatch(getUser({ queryParams, filters }));
  }, [
    dispatch,
    pagination?.page_size,
    pagination?.page_number,
    search,
    ordering,
    filter.params,
  ]);


  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    dispatch(setOrdering(sortBy));
  };



  const Action = (props) => {

    const { rowData } = props;
    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl);

    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    return (<>
      <IconButton onClick={handleClick}><MoreOption /></IconButton>

      {
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Grid className="p-1" onClick={() => {
            navigate(`profile/${rowData.id}`)
          }}>
            <Typography>View Detail</Typography>
          </Grid>
        </Popover>
      }
    </>
    )
  }

  const cellMap = {
    action: Action,

  };

  return (
    <Grid
      container
      className={className}
    >
      <Grid item xs={12} justifyContent={"space-between"} alignItems={"center"} className="dflex p25">
        <Typography variant="h4">User Management</Typography>
        <Button variant="contained" onClick={() => { navigate("profile") }}>+ Create New User</Button>
      </Grid>
      <Grid item xs={12} className="p-2 pt-0" style={{ height: "calc(100% - 84px)" }}>
        <div className={`table h-100`}>
          <Table
            title={"User List"}
            headers={[...(list?.headers || []), { field: 'action', label: 'action' }]}
            data={list?.results || []}
            cellMap={cellMap}
            search={search}
            pagination={pagination}
            handlePagination={handlePagination}
            handlePageSize={handlePageSize}
            handleSearch={handleSearch}
            ordering={ordering}
            orderBy={orderBy || []}
            onSort={onSort}

          />
          <div>{loading ? "Loading..." : ""}</div>
        </div>
      </Grid>
    </Grid>
  );
};

// default props
Users.defaultProps = {
  classes: {}
};

// prop types
Users.propTypes = {
  classes: PropTypes.object
};


export default styled(Users)(Style);
